import React from "react";
import {apiURL, strings} from "../Utils";
import Modal from "react-responsive-modal";
import axios from "axios";
import swal from "sweetalert";
import {headers} from "./config";

export default class FileListView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addFileModal: false
        }
    }

    openModal() {
        this.setState({
            addFileModal: true
        })
    }

    closeModal() {
        this.setState({
            addFileModal: false
        })
    }

    loadFile() {
        let fileType = document.getElementById('fileType').value;
        let userFile = document.getElementById('userFile').files[0];
        let data = new FormData();
        data.append('fileType', fileType);
        data.append('userFile', userFile);
        axios.post(apiURL + '/journal/file/upload', data, headers).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        });
    }

    render() {
        return(
            <div className="container-fluid mt-2">
                <div className="form-row">
                    <div className="col-md-2">
                        <button className="btn btn-success" onClick={this.openModal.bind(this)}>Fayl yuklash</button>
                    </div>
                    <div className="col-md-8 text-center">
                        <h4>Fayllar ro`yxati</h4>
                    </div>
                    <div className="col-md-2">
                        &nbsp;
                    </div>
                </div>
                <div className="form-row mt-1">
                    <div className="col-md-12">
                    </div>
                </div>

                <Modal open={this.state.addFileModal} onClose={this.closeModal.bind(this)} center>
                    <div className="m-1 form-row">
                        <div className="col-md-12">
                            <div className="modal-body">
                                <div className="addProductForm">
                                    <div className="addProductItem">
                                        <label htmlFor="fileType">{strings.release}</label>
                                        <select id="fileType" className="form-control">
                                            <option value={1}>Slayd rasmlari</option>
                                            <option value={2}>Jurnal muqovasi</option>
                                            <option value={3}>Maqola muallifi</option>
                                        </select>
                                    </div>
                                    <div className="addProductItem">
                                        <label htmlFor="userFile">{strings.imageFile}</label>
                                        <input type="file" id="userFile"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-success" onClick={this.loadFile.bind(this)}>Saqlash
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}