import {apiURL} from "../Utils";

export const headers = {
    headers: {
        'Content-Type': 'application/json',
        // 'Origin': publicResourceURL,
        'Access-Control-Allow-Origin': apiURL,
        'Access-Control-Allow-Headers': "Content-Type, X-Requested-With, accept, Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        // 'Access-Control-Allow-Credentials': "true",
        'X-XSRF-TOKEN': getCookie('X-XSRF-TOKEN'),
        'Authorization': localStorage.getItem('dhruser') !== undefined && localStorage.getItem('dhruser') !== null ? 'Bearer ' + JSON.parse(localStorage.getItem('dhruser')).token : null,
        // withCredentials: "true",
        mode: 'cors'
    }
}
export const headers2 = {
    headers: {

        'Content-Type': 'application/json',
        'Accept': 'application/json, text/plain, */*',
        // 'Accept-Encoding': 'gzip, deflate',
        'Pragma': 'no-cache',
        'Access-Control-Allow-Origin': 'http://dhrjournal.uz',
        'Access-Control-Allow-Headers': 'Content-Type, X-Requested-With, accept, Origin, Access-Control-Request-Method, Access-Control-Request-Headers',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        // 'Access-Control-Allow-Credentials': 'true',
        'Authorization': localStorage.getItem('dhruser') !== undefined && localStorage.getItem('dhruser') !== null ? 'Bearer ' + JSON.parse(localStorage.getItem('dhruser')).token : null,
        // 'mode': 'cors',
        // 'credentials': 'include'

    }
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}