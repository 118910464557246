import ControlledCarousel from "./components/ControlledCarousel";
import InternationalIndex from "./components/InternationalIndex";
import JournalsSlide from "./components/JournalsSlide";
import Map from "./components/Map";
import Contact from "./components/Contact";
import Founders from "./components/Founders";
import {withRouter} from "react-router-dom";
import NewsList from "./NewsList";
import React from "react";
import {strings} from "./Utils";


function HomePage() {
    return (
        <div className="container-fluid m-0 ">
            <div className="row">
                <div className="col-xl-9 px-0 ">
                    <ControlledCarousel/>
                    <NewsList/>
                    <JournalsSlide/>
                </div>
                <div className="col-xl-3" style={{paddingRight: "0"}}>
                    <InternationalIndex/>
                    <Founders/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <h3 className="text-center my-5">{strings.contact1}</h3>
                </div>
                <div className="col-md-6">
                    <h3 className="text-center my-5">{strings.contact2}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-5 p-0">
                    <Contact/>
                </div>
                <div className="col-lg-6 mb-5 px-5">
                    <Map/>
                </div>
            </div>
        </div>
    )

}

export default withRouter(HomePage);