import React from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {apiURL, strings} from "./Utils";

class NewsList extends React.Component {

    componentDidMount() {
        this.fetchNewsList();
    }
    componentDidUpdate() {
        this.fetchNewsList();
    }

    fetchNewsList() {
        let newsList = document.getElementById('newsList');
        newsList.innerHTML = '';
        const currentLanguageCode = localStorage.getItem('locale') || 'uz';
        axios.get(apiURL + '/api/news/list?lang=' + currentLanguageCode).then(result => {
            if (result.data.statusCode === 200) {
                let newsList = document.getElementById('newsList');
                result.data.data.map(item=>{
                    let div = document.createElement('div');
                    div.className="m-2";
                    div.innerHTML = item;
                    newsList.appendChild(div);
                    return null;
                });
            }
        })
    }

    render() {
        return(
            <div className="text-center">
                <h3 className="text-center pt-5">{strings.news}</h3>
                <div id="newsList" className="d-flex form-row"></div>
            </div>
        )
    }
}

export default withRouter(NewsList);