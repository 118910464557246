import {useState,} from 'react';
import {Button, Offcanvas,} from 'react-bootstrap';
import axios from 'axios';
import {apiURL, strings} from "../Utils";
import {withRouter} from "react-router-dom";

function LoginPage({name, ...props}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    const handleLogin = () => {
        let userName = document.getElementById('userName').value;
        let password = document.getElementById('password').value;
        let data = {
            "userName": userName,
            "password": password
        }
        axios.post(apiURL + '/api/user/login', data).then(result=>{
            if (Number(result.status) === 200) {
                localStorage.setItem('dhruser', JSON.stringify(result.data));
                if (result.data.roles.includes('SUBSCRIBER')) {
                    window.open('/user', '_self');
                } else if (result.data.roles.includes('ADMIN')) {
                    // axios.get(apiURL + "/admin")
                    //     .then(result2 => {
                            window.open('/admin', '_self');
                        // })
                }
            }
        })
    };

    return (
        <>
        <Button variant="link" onClick={handleShow} style={{color: "#FFFFFF"}}>
            {strings.enter}
        </Button>
        <Offcanvas show={show} onHide={handleClose} {...props} className="info">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{strings.enter}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="forms align-items-center ">
                    <div className="form-group w-75">
                        <label htmlFor="userName">{strings.email}</label>
                        <input type="email" className="form-control"
                               placeholder={strings.enterEmail} id="userName" value={email}
                               onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group w-75">
                        <label htmlFor="pasword">{strings.password}</label>
                        <input type="password" className="form-control"
                               placeholder={strings.enterPassword} id="password" value={password}
                               onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-primary" disabled={!validateForm()} onClick={handleLogin}>{strings.enter}</button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
        </>
    );
}

export default withRouter(LoginPage);