import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

function PublishProcedure() {
    const langCode = localStorage.getItem('locale') || 'uz';
    return (
        <div className="container bg-light px-5">
            <div className="title text-center py-4 ">
                <h2>{strings.publish1}</h2>
            </div>
            <div className="informations">
                <div className="information py-1">
                    <h5>{strings.publish2}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish3}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish4}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish5}</h5>
                </div>
                <div className="information py-1 px-5">
                    <h5>{strings.publish6}</h5>
                </div>
                <div className="information py-1 px-5">
                    <h5>{strings.publish7}</h5>
                </div>
                <div className="information py-1 px-5">
                    <h5>{strings.publish8}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish9}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish10}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish11}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish12}</h5>
                </div>
                <div className="information py-1">
                    <h5>{strings.publish13}</h5>
                </div>
            </div>
            <div className="text-center py-4">
                <a target="_blank" rel="noreferrer" className="btn btn-primary px-5 " href={"./files/publish_" + langCode + ".pdf"}>{strings.download}</a>
            </div>

        </div>
    )
}

export default withRouter(PublishProcedure);