import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

function AboutUs() {
    return (
        <div className="container members2">
            <h1 className="text-center py-4">{strings.leadership}</h1>
            <div className="information p-4 text-center">
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="shadow d-flex rounded p-3">
                            <div className="card-body">
                                <img src="./images/akmalSaidov.jpg" alt="" style={{margin: "15px", float: "left", height: "250px"}}/>
                                <h3 className="card-title">{strings.chairman}</h3>
                                <h3 className="card-title"><b>{strings.akmalSaidov2}</b></h3>
                                <p className="card-text text-justify">{strings.akmalSaidovDesc}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="shadow d-flex rounded p-3">
                            <img src="./images/erkin.jpg" alt="" style={{width: "200px", height: "250px"}}/>
                            <div className="p-3">
                                <h3 className="card-title">{strings.mainEditor}</h3>
                                <h3 className="card-title"><b>{strings.erkinErnazarov}</b></h3>
                                <p className="card-text">{strings.erkinErnazarovDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(AboutUs);