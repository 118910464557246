import React, {StrictMode, Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/js/bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'flag-icon-css/css/flag-icon.min.css'
import {BrowserRouter} from "react-router-dom";

const loading = (
    <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
);

ReactDOM.render(
    <Suspense fallback={loading}>
        <StrictMode>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </StrictMode>
    </Suspense>,
    document.getElementById('root')
);
