import React from "react";
import axios from "axios";
import {apiURL} from "../../Utils";
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import swal from "sweetalert";
import {headers, headers2} from "../config";

class ArticleListView extends React.Component {

    constructor() {
        super();
        this.state = {
            id: null,
            articles: [],
            addArticleModal: false,
            articleName:'',
            journalYear: 2022,
            journalOrder: '',
            articleLanguage: 'uz',
            doiNumber: '',
            authorName: ''
        }
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount() {
        this.getArticleList();
    }

    setValue(e) {
        let name = e.target.id;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    getArticleList() {
        axios.get(apiURL + '/api/journal/article/list', headers2).then(result => {
            if (result.data.statusCode === 200) {
                let rows = result.data.data.map((row, index) => {
                    return <tr>
                        <td>{index + 1}</td>
                        <td>{row.year + '/' + row.journalOrder}</td>
                        <td>{row.articleName}</td>
                        <td>{row.doiNumber}</td>
                        <td>{row.language}</td>
                        <td>{row.createdDate}</td>
                        <td className="d-flex">
                            <a href={'/editArticle?id=' + row.id} target="_blank" rel="noreferrer" className="btn btn-info" title="Tahrirlash"><i className="fa fa-edit"/></a>
                            <a href={row.articleURL} target="_blank" rel="noreferrer" className="btn btn-success" title="Ko`rish"><i className="fa fa-eye"/></a>
                            <button className="btn btn-danger ml-1" onClick={this.removeArticle.bind(this, row.id)}><i className="fa fa-trash" title="O`chirish"/></button>
                        </td>
                    </tr>
                });
                this.setState({
                    articles: rows
                })
            }
        })
    }

    addArticle() {
        let articlePDFFile = document.getElementById('articlePDFFile').files[0];
        let authorImageFile = document.getElementById('authorImageFile').files[0];
        let data = new FormData();
        data.append('articleName', this.state.articleName);
        data.append('year', this.state.journalYear);
        data.append('order', this.state.journalOrder);
        data.append('language', this.state.articleLanguage);
        data.append('doiNumber', this.state.doiNumber);
        data.append('articleFile', articlePDFFile);
        data.append('authorName', this.state.authorName);
        data.append('authorImageFile', authorImageFile);

        axios.post(apiURL + '/api/journal/article/add', data, headers).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    openModal() {
        this.setState({
            addArticleModal: true
        })
    }

    closeModal() {
        this.setState({
            addArticleModal: false
        })
    }

    removeArticle(id) {
        let data = new FormData();
        data.append('id', id);
        axios.post(apiURL + '/api/admin/article/remove', data, headers2).then(result=>{
            if (result.data.statusCode === 200) {
                this.getArticleList();
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    render() {
        return (
            <div className="container-fluid mt-2">
                <div className="form-row">
                    <div className="col-md-2">
                        <button className="btn btn-success" onClick={this.openModal.bind(this)}>Maqola yuklash</button>
                    </div>
                    <div className="col-md-8 text-center">
                        <h4>Maqolalar ro`yxati</h4>
                    </div>
                    <div className="col-md-2">
                        &nbsp;
                    </div>
                </div>
                <div className="form-row mt-1">
                    <div className="col-md-12">
                        <table className="table table-bordered table-primary">
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>Jurnal soni</th>
                                <th>Maqola nomi</th>
                                <th>DOI raqami</th>
                                <th>Maqola tili</th>
                                <th>Yuklangan vaqti</th>
                                <th>Amal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.articles}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Modal open={this.state.addArticleModal} onClose={this.closeModal.bind(this)} center>
                    <div className="m-1 form-row">
                        <div className="col-md-12">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <label htmlFor="articleName">Maqola nomi</label>
                                    <input id="articleName" className="form-control" defaultValue={this.state.articleName} onChange={this.setValue}/>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-md-2">
                                    <label htmlFor="journalYear">Yili</label>
                                    <input id="journalYear" type="number" className="form-control" defaultValue={this.state.journalYear} onChange={this.setValue}/>
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="journalOrder">Soni</label>
                                    <input id="journalOrder" type="number" className="form-control" defaultValue={this.state.journalOrder} onChange={this.setValue}/>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="articleLanguage">Maqola tili</label>
                                    <select id="articleLanguage" className="form-control" defaultValue={this.state.articleLanguage} onChange={this.setValue}>
                                        <option value='uz'>O`zbek tili</option>
                                        <option value='kr'>Ўзбек тили</option>
                                        <option value='ru'>Rus tili</option>
                                        <option value='en'>Ingliz tili</option>
                                    </select>
                                </div>
                                <div className="col-md-5">
                                    <label htmlFor="doiNumber">DOI raqami</label>
                                    <input id="doiNumber" className="form-control" defaultValue={this.state.doiNumber} onChange={this.setValue}/>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-md-6">
                                    <label htmlFor="authorName">Muallif nomi</label>
                                    <input id="authorName" className="form-control" defaultValue={this.state.authorName} onChange={this.setValue}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="authorImageFile">Muallif rasmi</label>
                                    <input id="authorImageFile" type="file" accept='image/jpeg, image/png' className="form-control"/>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-md-12">
                                    <label htmlFor="articlePDFFile">PDF fayli</label>
                                    <input id="articlePDFFile" type="file" accept='application/pdf' className="form-control"/>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-success" onClick={this.addArticle.bind(this)}>Saqlash
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ArticleListView;