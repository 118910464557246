import React from 'react';
import LocalizedStrings from 'react-localization';

// export const publicResourceURL = "http://localhost:3000";
export const publicResourceURL = "http://dhrjournal.uz";
export const apiURL = "http://dhrjournal.uz";
// export const apiURL = "http://localhost:90";

export const strings = new LocalizedStrings({
    uz: {
        app_title: "Sarlavha",
        language: "Tillar",
        organizationName: "Inson huquqlari boʻyicha Oʻzbekiston Respublikasi Milliy markazi",
        journalName1: "DEMOKRATLASHTIRISH VA INSON HUQUQLARI",
        journalName2: "ilmiy-maʼrifiy jurnal",
        phrase: "Xalq davlat idoralariga emas, davlat idoralari xalqimizga xizmat qilishi kerak.",
        shavkatMirziyoyev: "Shavkat MIRZIYOYEV",
        home: "Bosh sahifa",
        aboutJournal: "Jurnal haqida",
        allInformation1: "Umumiy maʼlumot",
        allInformation2: " DEMOKRATLASHTIRISH VA INSON HUQUQLARI JURNALI - ilmiy-maʻrifiy jurnal. Jurnal 2020-yil 16-martda Oʻzbekiston Respublikasi Prezidenti Administratsiyasi huzuridagi Axborot va ommaviy kommunikatsiyalar agentligida 0202 raqami bilan roʻyxatdan oʻtgan. “Demokratlashtirish va inson huquqlari” jurnali Oʻzbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Oliy attestatsiya komissiyasining huquqshunoslik, falsafa, tarix, siyosiy, islomshunoslik va sotsiologiya fanlari boʻyicha dissertatsiya asosiy ilmiy natijalarini chop etish tavsiya etilgan ilmiy nashrlar roʻyxatiga kiritilgan",
        litsenziya: "Guvohnoma",
        aboutus: "Rahbariyat",
        orgStructure: "Tahririyat tuzilmasi",
        members: "Tahrir kengashi",
        interMembers: "Xalqaro tahrir kengashi a’zolari",
        nationalMembers: "Tahrir hay’ati a’zolari",
        toAuthor: "Maqola mualliflariga",
        request: "Maqolaga qoʻyilgan talablar",
        publish: "Jurnalda maqola chiqarish tartibi",
        sendArticle: "Tahririyatga maqola yuborish",
        electronLibrary: "Elektron kutubxona",
        subscription: "Obuna",
        agreement: "Shartnoma",
        readAgreement: "Shartnoma namunasi bilan tanishish",
        downloadAgreement: "Shartnoma namunasini yuklab olish",
        articles: "Ilmiy Maqolalar",
        publishSubscription: "Jurnalning bosma shakliga obuna",
        electronSubscription: "Jurnalning elektron shakliga obuna",
        f_i_sh: "F. I. SH.",
        enterName: "Familiya, ism va sharifingiz",
        address: "Manzil",
        enterAddress: "Yashash manzilingizni kiriting",
        email: "E-mail",
        enterEmail: "Elektron pochtangizni kiriting",
        incorrectEmail: "Elektron pochta xato kiritilgan",
        phoneNumber: "Telefon raqam",
        enterPhoneNumber: "Telefon raqamingizni kiriting",
        incorrectPhoneNumber: "Telefon raqami xato kirilgan",
        enterComment: "Izohni kiriting",
        buy: "Sotib olish",
        read: "O`qish",
        subscribe: "Obuna bo`lish",
        cancel: "Bekor qilish",
        send: "Yuborish",
        enter: "Kirish",
        password: "Parol",
        enterPassword: "Parolni kiriting",
        Singin: "Roʻyxatdan oʻtish",
        lastName: "Familiyasi",
        firstName: "Ismi",
        enterLastName: "Familiyangizni kiriting",
        enterFirstName: "Ismingizni kiriting",
        forReference: "Murojaatlar uchun",
        streetName: "Toshkent shahri, Islom Karimov koʻchasi, 15-uy",
        workTime: "Dushanba - Juma:",
        article: "Maqola",
        internationalIndexes: "Jurnal  roʻyxatdan oʻtgan xalqaro indekslar",
        makeSayt: "Sayt yaratuvchi: ",
        legalServices: "Yuridik xizmatlar Toshkentda",
        contact1: "Biz bilan bogʻlanish",
        contact2: "Bizning manzil",
        download: "Yuklab olish",
        journalRequests: "Maqolalarga qoʻyilgan asosiy talablar",
        journalRequests1: "Maqola MS WORD matn muharririda, Times New Roman shriftida 14 yozuv oʻlchami kattaligida, 1.5 intervalda boʻlishi lozim.",
        journalRequests2: "Matn A4 hajmdagi sahifada yuqoridan va pastdan 2 sm, chapdan 3 sm, oʻngdan 1.5 sm masofa qoldirib joylashtirilishi kerak.",
        journalRequests3: "Maqolaning hajmi annotatsiya va kalit soʻzlar bilan qoʻshib hisoblaganda 6-12 sahifadan iborat boʻlishi mumkin.",
        journalRequests4: "Maqola orfografik va stilistik xatolardan xoli boʻlishi hamda oʻzgalarning materiallaridan koʻchirib olish (plagiat) holatlari uchramasligi kerak.",
        journalRequests5: "Havolalar (snoskalar) soni kamida 8 ta, koʻpi bilan 30 tagacha boʻlishi tavsiya etiladi. Ilmiy maqolada oxirgi 15 yilda chop etilgan kamida 4 ta manbaga havola boʻlishi zarur. ",
        journalRequests6: "Mavzu nomi bosh harflarda va qoraytirilgan holda, mavzudan soʻng esa muallif(lar)ning F.I.Sh. va ish (oʻqish) joyi toʻliq  (qisqartmalarsiz), shuningdek ilmiy unvoni hamda darajasi keltiriladi. Barcha maʼlumotlar oʻzbek, rus, ingliz tillarida boʻlishi shart.",
        journalRequests7: "Maqolada annotatsiya oʻzbek, rus va ingliz tillarida (12 yozuv oʻlchami, 1.15 interval oraliqda) yozilishi lozim. Annotatsiyada mavzuning tasnifi, ishning maqsadi va erishilgan natijalar, ishning ilmiy yangiligi bilan bogʻliq maʼlumotlar beriladi. Soʻzlar soni 50-200 soʻz oraligʻida boʻlishi maqsadga muvofiq.",
        journalRequests8: "Kalit soʻz va atamalar uch tilda: oʻzbek, rus va ingliz tillarida beriladi, hamda 5 tadan kam boʻlmasligi tavsiya etiladi.",
        journalRequests9: "Maqola matni kengligi boʻyicha tekislangan boʻlishi kerak.",
        journalRequests10: "Maqolaga havolalar (snoskalar) sahifa pastida berilishi kerak. ",
        journalRequests11: "Jadvallar “jadval” soʻzi bilan ifodalanib, arab raqamlari bilan qayd etiladi. Jadval nomidan keyin nuqta qoʻyilmaydi va oʻng tomonga joylashtiriladi.",
        journalRequests12: "Maqoladagi maʼlumot va dalillarning aniqligi, toʻgʻriligi hamda koʻchirmakashlikka (plagiatga) yoʻl qoʻyilmaganligiga muallif javobgardir. ",
        journalRequests13: "Rasmiylashtirish boʻyicha axborot xati yakunida NAMUNA berilgan. Ushbu namunadan foydalanish tavsiya etiladi.",
        journalRequests14: "Ushbu talablarga javob bermagan materiallar jurnalda chop etish uchun qabul qilinmaydi.",
        markFile: "Kerakli fayli belgilang",
        chooseLanguages: "Tilni tanlang",
        helpVideo: "DOI raqamini tekshirish boʻyicha video qoʻllanma",
        founder: "Muassis:",
        founder2: "Hammuassislar:",
        allJournals: "JURNAL SONLARI",
        tradeUnions: "Oʻzbekiston kasaba uyushmalari Federatsiyasi",
        ombudsman: "Oʻzbekiston Respublikasi Oliy Majlisining Inson huquqlari boʻyicha vakili (Ombudsman)",
        payments: "Toʻlovlar",
        subscribers: "Obunachilar",
        uploadFile: "Yuklash",
        uploadFileHeader: "Jurnal yangi sonini yuklash",
        year: "yil",
        year2: "Yil",
        release: "Son",
        imageFile: "Muqova rasm fayli",
        pdfFile: "Jurnal PDF fayli",
        coverPdfFile: "Mundarija PDF fayli",
        logout: "Chiqish",
        save: "Saqlash",
        payment: "Toʻlov",
        edit: "Tahrirlash",
        autobiography: "Tarjimai holi",
        chairman: "Tahrir kengashi raisi",
        mainEditor: "Bosh muharrir",
        leadership: "Rahbariyat",
        akmalSaidov: "Akmal Saidov",
        akmalSaidov2: "Saidov Akmal Xolmatovich",
        akmalSaidovDesc: "O‘zbekiston Respublikasi Oliy Majlisi Qonunchilik palatasi Spikerining birinchi o‘rinbosari, Inson huquqlari bo‘yicha O‘zbekiston Respublikasi Milliy markazi direktori, O‘zbekiston Respublikasi Fanlar akademiyasining haqiqiy a’zosi, yuridik fanlar doktori, professor.",
        erkinErnazarov: "Ernazarov Erkin Madiyorovich",
        erkinErnazarovDesc: "O‘zbekiston Respublikasida xizmat ko‘rsatgan jurnalist",
        tanzilaNarbayeva: "Tanzila Narbayeva",
        tanzilaNarbayevaDesc: "O‘zbekiston Respublikasi Oliy Majlisi Senati Raisi.",
        qudratillaRafiqov: "Qudratilla Rafiqov",
        qudratillaRafiqovDesc: "O‘zbekiston kasaba uyushmalari Federatsiyasi raisi.",
        halimBoboyev: "Halim Boboyev",
        halimBoboyevDesc: "O‘zbekiston Respublikasi fan arbobi, Toshkent davlat yuridik universiteti professori, yuridik fanlar doktori.",
        mixailGasanov: "Mixail Gasanov",
        mixailGasanovDesc: "Toshkent davlat yuridik universiteti dotsenti, yuridik fanlar nomzodi.",
        mirzatilloTillabayev: "Mirzatillo Tillabayev",
        mirzatilloTillabayevDesc: "Inson huquqlari bo‘yicha O‘zbekiston Respublikasi Milliy markazi direktorining birinchi o‘rinbosari, yuridik fanlar doktori, dotsent.",
        omonOqyulov: "Omon Oqyulov",
        omonOqyulovDesc: "O‘zbekiston Respublikasida xizmat ko‘rsatgan yurist, Davlat va huquq instituti bosh ilmiy xodimi, Toshkent davlat yuridik universiteti professori, yuridik fanlar doktori.",
        asqarGafurov: "Asqar G‘afurov",
        asqarGafurovDesc: "O‘zbekiston Respublikasi Konstitutsiyaviy sudi raisining o‘rinbosari, yuridik fanlar nomzodi, dotsent.",
        muzaffarMamasiddiqov: "Muzaffar Mamasiddiqov",
        muzaffarMamasiddiqovDesc: "O‘zbekiston Respublikasi Bosh prokuraturasi Akademiyasi boshlig‘ining o‘rinbosari, yuridik fanlar doktori, professor.",
        elizavetaKutibayeva: "Yelizaveta Kutibayeva",
        elizavetaKutibayevaDesc: "Berdaq nomidagi Qoraqalpoq davlat universitetining Yuridik fakulteti dekani, yuridik fanlar doktori, professor.",
        haydaraliMuxamedov: "Xaydarali Muxamedov",
        haydaraliMuxamedovDesc: "Toshkent davlat yuridik universiteti professori, yuridik fanlar nomzodi.",
        aslan: "Aslan Abashidze",
        aslanContry: "(Rossiya Federatsiyasi)",
        aslanDesc: "Rossiya xalqlar do‘stligi universiteti Xalqaro huquq kafedrasi mudiri, yuridik fanlar doktori, professor.",
        akira: "Akira Fujimoto",
        akiraContry: "(Yaponiya)",
        akiraDesc: "Nagoya universitetining Osiyo huquqiy almashinuv markazi direktori, yuridik fanlar doktori, professor.",
        uilyam: "Uilyam Batler",
        uilyamCountry: "(AQSH)",
        uilyamDesc: "Pensilvaniya shtati universiteti qoshidagi Dikinson yuridik maktabi hamda London universiteti faxriy professori, yuridik fanlar doktori.",
        bodio: "Tadeush Bodio",
        bodioCountry: "(Polsha)",
        bodioDesc: "Varshava universiteti professori, falsafa fanlari doktori.",
        margub: "Margʻub Salim Butt",
        margubCountry: "(Islom Hamkorlik tashkiloti)",
        margubDesc: "Islom hamkorlik tashkiloti Inson huquqlari bo‘yicha mustaqil doimiy komissiyasi kotibiyatining ijrochi direktori.",
        reyn: "Reyn Myullerson",
        reynCountry: "(Estoniya)",
        reynDesc: "Tallin universiteti faxriy professori, yuridik fanlar doktori.",
        tatyana: "Tatyana Moskalkova ",
        tatyanaCountry: "(Rossiya Federatsiyasi)",
        tatyanaDesc: "Rossiya Federatsiyasining Inson huquqlari bo‘yicha vakili, yuridik fanlar doktori, professor.",
        sergey: "Sergey Udarsev ",
        sergeyCountry: "Qozogʻiston",
        sergeyDesc: "Qozog‘iston gumanitar-yuridik universiteti Huquqiy siyosat va konstitutsiyaviy qonunchilik ilmiy-tadqiqot instituti direktori, yuridik fanlar doktori, professor. ",
        content: "Mundarija",
        typeElectron: "Elektron shaklda",
        typePrinted: "Bosma shaklda",
        years: "yil",
        years2: "Yillar",
        releases: "Jurnal sonlari: ",
        release_1: "1-son",
        release_2: "2-son",
        release_3: "3-son",
        release_4: "4-son",
        buyCount: "Xarid soni",
        selectedReleases: "Tanlangan sonlar",
        aboutJournal1: "“DEMOKRATLASHTIRISH VA INSON HUQUQLARI” ILMIY-MAʼRIFIY JURNALI HAQIDA ",
        aboutJournal2: "Oʻzbekiston Respublikasi Prezidentining 1996-yil 31-oktyabrdagi “Inson huquqlari boʻyicha Oʻzbekiston Respublikasi Milliy markazini tuzish toʻgʻrisida”gi Farmoni asosida “Demokratlashtirish va inson huquqlari” jurnali tashkil etilgan.",
        aboutJournal3: "“Demokratlashtirish va inson huquqlari” jurnali 1999-yil 28-yanvarda ommaviy axborot vositasi sifatida roʻyxatga olingan va shu yili jurnalning ilk soni nashr etilgan.",
        aboutJournal4: "Jurnal yilning har choragida oʻzbek, ingliz va rus tillarida nashr etiladi.\n" +
            "Bugungi kunda jurnal Oʻzbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Oliy attestatsiya komissiyasining: ",
        aboutJournal5: "siyosiy fanlar; ",
        aboutJournal6: "huquqshunoslik;",
        aboutJournal7: "falsafa;",
        aboutJournal8: "tarix;",
        aboutJournal9: "islomshunoslik;",
        aboutJournal10: "sotsiologiya fanlari boʻyicha dissertatsiya asosiy ilmiy natijalarini chop etish tavsiya etilgan ilmiy nashrlar roʻyxatiga kiritilgan.",
        aboutJournal11: "“Demokratlashtirish va inson huquqlari” jurnalida jami 2134 ta ilmiy maqola eʼlon qilingan. Shundan, 1889 ta maqola oʻzbekistonlik mualliflar tomonidan, 245 ta maqola xorijlik mualliflar tomonidan chop etilgan.",
        publish1: "MAQOLA CHIQARISH TARTIB-QOIDALARI:",
        publish2: "1. Mualliflar, birinchi navbatda, maqolaga qoʻyilgan talablar bilan tanishib chiqishlari va shu asosida maqola yozishlari zarur.",
        publish3: "2. Maqola siyosiy fanlar, shuningdek huquqshunoslik, falsafa, tarix, islomshunoslik va sotsiologiya fanlari doirasida boʻlishi maqsadga muvofiq. Inson huquqlari sohasiga oid masalalar yoritilishi tavsiya etiladi.",
        publish4: "3. Maqolalar original, muqaddam biror nashrda eʼlon qilinmagan bo`lishi kerak; maqolalar oʻzbek, rus yoki ingliz tillaridan birida qabul qilinadi.",
        publish5: "4. Maqola 3 xil usulda tahririyatga yoʻllanishi mumkin:",
        publish6: "• Mualliflar tahririyatga kelib, elektron va qogʻoz shaklida maqola topshirishlari mumkin.",
        publish7: "• journal@nhrc.uz elektron pochtasiga yuborishlari mumkin.",
        publish8: "• Jurnal veb-sayti orqali yuborishlari mumkin.",
        publish9: "5. Tahririyatga kelgan maqolalar jurnal talablariga muvofiqligi tekshiriladi, talablarga javob bergan maqolalar roʻyxatga olish asosida qabul qilinadi.",
        publish10: "6. Qabul qilingan maqolalarni Tahrir kengashi anonim tarzda koʻrib chiqadi va xulosa beradi.",
        publish11: "7. Tahrir kengashi xulosasi asosida maqola jurnal veb-saytiga joylashtiriladi yoki rad etiladi. Veb-saytdagi maqolalardan eng saralari anʼanaviy shakldagi jurnalda ham eʼlon qilinadi.",
        publish12: "8. Tahririyat maqolalarni qisqartirish, toʻgʻrilash va tahrir qilish huquqiga ega. ",
        publish13: "9. Belgilangan tartibda rasmiylashtirilmagan, mavzusi va yoʻnalishlari mos kelmaydigan maqolalar Tahrir kengashi tomonidan koʻrib chiqilmaydi va muallifga qaytarilmaydi.",
        news: "Xabarlar ",
        doiNumber: "DOI raqami: ",
        articleDate: "Sana: ",
        downloadSamples: "Namunani yuklab olish",
        previous: "Oldingi",
        next: "Keyingi",
        articleList: "Maqolalar ro`yxati",
        journalList: "Jurnallar ro`yxati",
        fileList: "Fayllar ro`yxati",
        search: "Qidiruv",
        yourFeedbackSent: "Fikringiz tahririyatga yuborildi",
        errorWhenSentYourFeedback: "Fikringizni yuborishda xatolik yuzaga keldi"
    },
    kr: {
        app_title: "Сарлавҳа",
        language: "Тиллар",
        organizationName: "Инсон ҳуқуқлари бўйича Ўзбекистон Республикаси Миллий маркази",
        journalName1: "ДEМОКРАТЛАШТИРИШ ВА ИНСОН ҲУҚУҚЛАРИ",
        journalName2: "илмий-маърифий журнал",
        phrase: "Халқ давлат идораларига эмас, давлат идоралари халқимизга хизмат қилиши керак.",
        shavkatMirziyoyev: "Шавкат МИРЗИЁЕВ",
        home: "Бош саҳифа",
        aboutJournal: "Журнал ҳақида",
        allInformation1: "Умумий малумот",
        allInformation2: "ДEМОКРАТЛАШТИРИШ ВА ИНСОН ҲУҚУҚЛАРИ ЖУРНАЛИ илмий-маърифий журнал. Журнал 2020 йил 16 мартда Ўзбекистон Республикаси Президенти Администрацияси ҳузуридаги Ахборот ва оммавий коммуникациялар агентлигида 0202 рақами билан рўйхатдан ўтган. «Демократлаштириш ва инсон ҳуқуқлари» журнали Ўзбекистон Республикаси Вазирлар Маҳкамаси ҳузуридаги Олий аттестация комиссиясининг ҳуқуқшунослик, фалсафа, тарих, сиёсий, исломшунослик ва социология фанлари бўйича диссертация асосий илмий натижаларини чоп этиш тавсия этилган илмий нашрлар рўйхатига киритилган",
        litsenziya: "Гувоҳнома",
        aboutus: "Раҳбарият",
        orgStructure: "Таҳририят тузилмаси",
        members: "Таҳрир кенгаши",
        interMembers: "Халқаро таҳрир кенгаши аъзолари",
        nationalMembers: "Таҳрир ҳайъати аъзолари",
        toAuthor: "Мақола муаллифларига",
        request: "Мақолага қўйилган талаблар",
        publish: "Журналда мақола чиқариш тартиби",
        sendArticle: "Таҳририятга мақола юбориш",
        electronLibrary: "Электрон кутубхона",
        subscription: "Обуна",
        agreement: "Шартнома",
        readAgreement: "Шартнома намунаси билан танишиш",
        downloadAgreement: "Шартнома намунасини юклаб олиш",
        articles: "Илмий мақолалар",
        publishSubscription: "Журналнинг босма шаклига обуна",
        electronSubscription: "Журналнинг электрон шаклига обуна",
        f_i_sh: "Ф. И. Ш.",
        enterName: "Фамилия, исм ва шарифингиз",
        address: "Манзил",
        enterAddress: "Яшаш манзилингизни киритинг",
        email: "Электрон почта",
        enterEmail: "Электрон почтангизни киритинг",
        incorrectEmail: "Электрон почта хато киритилган",
        phoneNumber: "Телефон рақам",
        enterPhoneNumber: "Телефон рақамингизни киритинг",
        incorrectPhoneNumber: "Телефон рақами хато киритилган",
        enterComment: "Изоҳни киритинг",
        buy: "Сотиб олиш",
        read: "Ўқиш",
        subscribe: "Обуна бщлиш",
        cancel: "Бекор қилиш",
        send: "Юбориш",
        enter: "Кириш",
        password: "Пароль",
        enterPassword: "Паролни киритинг",
        Singin: "Рўйхатдан ўтиш",
        lastName: "Фамилияси",
        firstName: "Исми",
        enterLastName: "Фамилиянгизни киритинг",
        enterFirstName: "Исмингизни киритинг",
        forReference: "Мурожаатлар учун",
        streetName: "Тошкент шаҳри, Ислом Каримов кўчаси, 15-уй",
        workTime: "Душанба - Жума:",
        article: "Мақола",
        internationalIndexes: "Журнал рўйхатдан ўтган халқаро индекслар",
        makeSayt: "Сайт яратувчи: ",
        legalServices: "Юридик хизматлар Тошкентда",
        contact1: "Биз билан боғланиш",
        contact2: "Бизнинг манзил",
        download: "Юклаб олиш",
        journalRequests: "ИЛМИЙ МАҚОЛАГА ҚЎЙИЛГАН ТАЛАБЛАР: ",
        journalRequests1: "Мақола MS WORD матн муҳарририда, Times New Roman шрифтида 14 ёзув ўлчами катталигида, 1.5 интервалда бўлиши лозим.",
        journalRequests2: "Матн А4 ҳажмдаги саҳифада юқоридан ва пастдан 2 см, чапдан 3 см, ўнгдан 1.5 см масофа қолдириб жойлаштирилиши керак.",
        journalRequests3: "Мақоланинг ҳажми аннотация ва калит сўзлар билан қўшиб ҳисоблаганда 6-12 саҳифадан иборат бўлиши мумкин.",
        journalRequests4: "Мақола орфографик ва стилистик хатолардан холи бўлиши ҳамда ўзгаларнинг материалларидан кўчириб олиш (плагиат) ҳолатлари учрамаслиги керак.",
        journalRequests5: "Ҳаволалар (сноскалар) сони камида 8 та, кўпи билан 30 тагача бўлиши тавсия этилади. Илмий мақолада охирги 15 йилда чоп этилган камида 4 та манбага ҳавола бўлиши зарур. ",
        journalRequests6: "Мавзу номи бош ҳарфларда ва қорайтирилган ҳолда, мавзудан сўнг эса муаллиф(лар)нинг Ф.И.Ш. ва иш (ўқиш) жойи тўлиқ  (қисқартмаларсиз), шунингдек илмий унвони ҳамда даражаси келтирилади. Барча маълумотлар ўзбек, рус, инглиз тилларида бўлиши шарт.",
        journalRequests7: "Мақолада аннотация ўзбек, рус ва инглиз тилларида (12 ёзув ўлчами, 1.15 интервал оралиқда) ёзилиши лозим. Аннотацияда мавзунинг таснифи, ишнинг мақсади ва эришилган натижалар, ишнинг илмий янгилиги билан боғлиқ маълумотлар берилади. Сўзлар сони 50-200 сўз оралиғида бўлиши мақсадга мувофиқ.",
        journalRequests8: "Калит сўз ва атамалар уч тилда: ўзбек, рус ва инглиз тилларида берилади, ҳамда 5 тадан кам бўлмаслиги тавсия этилади.",
        journalRequests9: "Мақола матни кенглиги бўйича текисланган бўлиши керак.",
        journalRequests10: "Мақолага ҳаволалар (сноскалар) саҳифа пастида берилиши керак. ",
        journalRequests11: "Жадваллар “жадвал” сўзи билан ифодаланиб, араб рақамлари билан қайд этилади. Жадвал номидан кейин нуқта қўйилмайди ва ўнг томонга жойлаштирилади.",
        journalRequests12: "Мақоладаги маълумот ва далилларнинг аниқлиги, тўғрилиги ҳамда кўчирмакашликка (плагиатга) йўл қўйилмаганлигига муаллиф жавобгардир. ",
        journalRequests13: "Расмийлаштириш бўйича ахборот хати якунида НАМУНА берилган. Ушбу намунадан фойдаланиш тавсия этилади.",
        journalRequests14: "Ушбу талабларга жавоб бермаган материаллар журналда чоп этиш учун қабул қилинмайди.",
        markFile: "Керакли файли белгиланг",
        chooseLanguages: "Тилни танланг",
        helpVideo: "DOI рақамини текшириш бўйича видео қўлланма",
        founder: "Муассис:",
        founder2: "Ҳаммуассислар:",
        allJournals: "Журнал сонлари",
        tradeUnions: "Ўзбекистон касаба уюшмалари Федерацияси",
        ombudsman: "Ўзбекистон Республикаси Олий Мажлисининг Инсон ҳуқуқлари буйича вакили (Омбудсман)",
        payments: "Тўловлар",
        subscribers: "Обуначилар",
        uploadFile: "Юклаш",
        uploadFileHeader: "Журналнинг янги сонини юклаш",
        year: "йил",
        year2: "Йил",
        release: "Сон",
        imageFile: "Муқова расми файли",
        pdfFile: "Журнал PDF файли",
        coverPdfFile: "PDF файли (Мундарижа)",
        logout: "Чиқиш",
        save: "Сақлаш",
        payment: "Тўлов",
        edit: "Таҳрирлаш",
        autobiography: "Таржимаи ҳоли",
        chairman: "Таҳрир кенгаши раиси",
        mainEditor: "Бош муҳаррир",
        leadership: "Раҳбарият",
        akmalSaidov: "Акмал Саидов",
        akmalSaidov2: "Саидов Акмал Холматович",
        akmalSaidovDesc: "Ўзбекистон Республикаси Олий Мажлиси Қонунчилик палатаси Спикерининг биринчи ўринбосари, Инсон ҳуқуқлари бўйича Ўзбекистон Республикаси Миллий маркази директори, Ўзбекистон Республикаси Фанлар академиясининг ҳақиқий аъзоси, юридик фанлар доктори, профессор.",
        erkinErnazarov: "Эрназаров Эркин Мадиёрович",
        erkinErnazarovDesc: "Ўзбекистон Республикасида хизмат кўрсатган журналист",
        tanzilaNarbayeva: "Танзила Нарбаева",
        tanzilaNarbayevaDesc: "Ўзбекистон Республикаси Олий Мажлиси Сенати Раиси.",
        qudratillaRafiqov: "Қудратилла Рафиқов",
        qudratillaRafiqovDesc: "Ўзбекистон касаба уюшмалари Федерацияси раиси.",
        halimBoboyev: "Ҳалим Бобоев",
        halimBoboyevDesc: "Ўзбекистон Республикаси фан арбоби, Тошкент давлат юридик университети профессори, юридик фанлар доктори.",
        mixailGasanov: "Михаил Гасанов",
        mixailGasanovDesc: "Тошкент давлат юридик университети доценти, юридик фанлар номзоди.",
        mirzatilloTillabayev: "Мирзатилло Тиллабаев",
        mirzatilloTillabayevDesc: "Инсон ҳуқуқлари бўйича Ўзбекистон Республикаси Миллий маркази директорининг биринчи ўринбосари, юридик фанлар доктори, доцент.",
        omonOqyulov: "Омон Оқюлов",
        omonOqyulovDesc: "Ўзбекистон Республикасида хизмат кўрсатган юрист, Давлат ва ҳуқуқ институти бош илмий ходими, Тошкент давлат юридик университети профессори, юридик фанлар доктори.",
        asqarGafurov: "Aсқар Ғафуров",
        asqarGafurovDesc: "Ўзбекистон Республикаси Конституциявий суди раисининг ўринбосари, юридик фанлар номзоди, доцент.",
        muzaffarMamasiddiqov: "Музаффар Мамасиддиков",
        muzaffarMamasiddiqovDesc: "Ўзбекистон Республикаси Бош прокуратураси Академияси бошлиғининг ўринбосари, юридик фанлар доктори, профессор.",
        elizavetaKutibayeva: "Елизавета Кутибаева",
        elizavetaKutibayevaDesc: "Бердақ номидаги Қорақалпоқ давлат университетининг Юридик факультети декани, юридик фанлар доктори, профессор.",
        haydaraliMuxamedov: "Хайдарали Мухамедов",
        haydaraliMuxamedovDesc: "Тошкент давлат юридик университети профессори, юридик фанлар номзоди.",
        aslan: "Аслан Абашидзе",
        aslanContry: "(Россия Федерацияси)",
        aslanDesc: "Россия халқлар дўстлиги университети Халқаро ҳуқуқ кафедраси мудири, юридик фанлар доктори, профессор.",
        akira: "Акира Фужимото",
        akiraContry: "(Япония)",
        akiraDesc: "Нагоя университетининг Осиё ҳуқуқий алмашинув маркази директори, юридик фанлар доктори, профессор.",
        uilyam: "Уилям Батлер",
        uilyamCountry: "(АҚШ)",
        uilyamDesc: "Пенсильвания штати университети қошидаги Дикинсон юридик мактаби ҳамда Лондон университети фахрий профессори, юридик фанлар доктори.",
        bodio: "Тадеуш Бодио",
        bodioCountry: "(Полша)",
        bodioDesc: "Варшава университети профессори, фалсафа фанлари доктори.",
        margub: "Марғуб Салим Бутт",
        margubCountry: "(Ислом Ҳамкорлик ташкилоти)",
        margubDesc: "Ислом ҳамкорлик ташкилоти Инсон ҳуқуқлари бўйича мустақил доимий комиссияси котибиятининг ижрочи директори.",
        reyn: "Рейн Мюллерсон",
        reynCountry: "(Эстония)",
        reynDesc: "Таллин университети фахрий профессори, юридик фанлар доктори.",
        tatyana: " Татяна Москалкова ",
        tatyanaCountry: "(Россия Федерацияси)",
        tatyanaDesc: "Россия Федерациясининг Инсон ҳуқуқлари бўйича вакили, юридик фанлар доктори, профессор.",
        sergey: "Сергей Ударцев",
        sergeyCountry: "Қозоғистон",
        sergeyDesc: "Қозоғистон гуманитар-юридик университети Ҳуқуқий сиёсат ва конституциявий қонунчилик илмий-тадқиқот институти директори, юридик фанлар доктори, профессор.",
        content: "Мундарижа",
        typeElectron: "Электрон шаклда",
        typePrinted: "Босма шаклда",
        years: "йил",
        years2: "Йиллар",
        releases: "Журнал сонлари: ",
        release_1: "1-сон",
        release_2: "2-сон",
        release_3: "3-сон",
        release_4: "4-сон",
        buyCount: "Харид сони",
        selectedReleases: "Танланган сонлар",
        aboutJournal1: "“ДЕМОКРАТЛАШТИРИШ ВА ИНСОН ҲУҚУҚЛАРИ” ИЛМИЙ-МАЪРИФИЙ ЖУРНАЛИ ҲАҚИДА ",
        aboutJournal2: "Ўзбекистон Республикаси Президентининг 1996 йил 31 октябрдаги “Инсон ҳуқуқлари бўйича Ўзбекистон Республикаси Миллий марказини тузиш тўғрисида”ги Фармони асосида “Демократлаштириш ва инсон ҳуқуқлари” журнали ташкил этилган.",
        aboutJournal3: "“Демократлаштириш ва инсон ҳуқуқлари” журнали 1999 йил 28 январда оммавий ахборот воситаси сифатида рўйхатга олинган ва шу йили журналнинг илк сони нашр этилган.",
        aboutJournal4: "Журнал йилнинг ҳар чорагида ўзбек, инглиз ва рус тилларида нашр этилади.\n" +
            "Бугунги кунда журнал Ўзбекистон Республикаси Вазирлар Маҳкамаси ҳузуридаги Олий аттестация комиссиясининг: \n",
        aboutJournal5: "сиёсий фанлар; ",
        aboutJournal6: "ҳуқуқшунослик;",
        aboutJournal7: "фалсафа;",
        aboutJournal8: "тарих;",
        aboutJournal9: "исломшунослик;",
        aboutJournal10: "социология фанлари бўйича диссертация асосий илмий натижаларини чоп этиш тавсия этилган илмий нашрлар рўйхатига киритилган.",
        aboutJournal11: "“Демократлаштириш ва инсон ҳуқуқлари” журналида жами 2134 та илмий мақола эълон қилинган. Шундан, 1889 та мақола ўзбекистонлик муаллифлар томонидан, 245 та мақола хорижлик муаллифлар томонидан чоп этилган.",
        publish1: "МАҚОЛА ЧИҚАРИШ ТАРТИБ-ҚОИДАЛАРИ:",
        publish2: "1. Муаллифлар, биринчи навбатда, мақолага қўйилган талаблар билан танишиб чиқишлари ва шу асосида мақола ёзишлари зарур.",
        publish3: "2. Мақола сиёсий фанлар, шунингдек ҳуқуқшунослик, фалсафа, тарих, исломшунослик ва социология фанлари доирасида бўлиши мақсадга мувофиқ. Инсон ҳуқуқлари соҳасига оид масалалар ёритилиши тавсия этилади.",
        publish4: "3. Мақолалар оригинал, муқаддам бирор нашрда эълон қилинмаган, ўзбек, рус ёки инглиз тилларидан бирида қабул қилинади.",
        publish5: "4. Мақола 3 хил усулда таҳририятга йўлланиши мумкин:",
        publish6: "• Муаллифлар таҳририятга келиб, электрон ва қоғоз шаклида мақола топширишлари мумкин.",
        publish7: "• journal@nhrc.uz электрон почтасига юборишлари мумкин.",
        publish8: "• Журнал веб-сайти орқали юборишлари мумкин.",
        publish9: "5. Таҳририятга келган мақолалар журнал талабларига мувофиқлиги текширилади, талабларга жавоб берган мақолалар рўйхатга олиш асосида қабул қилинади.",
        publish10: "6. Қабул қилинган мақолаларни Таҳрир кенгаши аноним тарзда кўриб чиқади ва хулоса беради.",
        publish11: "7. Таҳрир кенгаши хулосаси асосида мақола журнал веб-сайтига жойлаштирилади ёки рад этилади. Веб-сайтдаги мақолалардан энг саралари анъанавий шаклдаги журналда ҳам эълон қилинади.",
        publish12: "8. Таҳририят мақолаларни қисқартириш, тўғрилаш ва таҳрир қилиш ҳуқуқига эга. ",
        publish13: "9. Белгиланган тартибда расмийлаштирилмаган, мавзуси ва йўналишлари мос келмайдиган мақолалар Таҳрир кенгаши томонидан кўриб чиқилмайди ва муаллифга қайтарилмайди.",
        news: "Хабарлар",
        doiNumber: "DOI рақами: ",
        articleDate: "Сана: ",
        downloadSamples: "Намунани юклаб олиш",
        previous: "Олдинги",
        next: "Кейинги",
        articleList: "Мақолалар рўйхати",
        journalList: "Журналлар рўйхати",
        fileList: "Файллар рўйхати",
        search: "Қидирув",
        yourFeedbackSent: "Фикрингиз таҳририятга юборилди",
        errorWhenSentYourFeedback: "Фикрингизни юборишда хатолик юзага келди"
    },
    ru: {
        app_title: "Title",
        language: "Языки",
        organizationName: "Национальный центр Республики Узбекистан по правам человека",
        journalName1: "ДЕМОКРАТИЗАЦИЯ И ПРАВА ЧЕЛОВЕКА",
        journalName2: "научно-просветительский журнал",
        phrase: "Не народ служит государственным органам, а государственные органы должны служить народу.",
        shavkatMirziyoyev: "Шавкат МИРЗИЁЕВ",
        home: "Главная",
        aboutJournal: "Журнал",
        allInformation1: "Общая информация",
        allInformation2: " ЖУРНАЛ ДЕМОКРАТИЗАЦИЯ И ПРАВ ЧЕЛОВЕКА. Научно-просветительский журнал 16 марта 2020 г зарегистрирован в Агентстве информации и массовых коммуникаций при Администрации Президента Республики Узбекистан 16 марта 2020 г. под номером 0202. Журнал «Демократизация и права человека» включен в Перечень научных изданий, рекомендованных Высшей аттестационной комиссией при Кабинете Министров Республики Узбекистан к публикации основных научных результатов диссертаций по праву, философии, истории, политологии, исламоведению и социологии.",
        litsenziya: "Cвидетельство",
        aboutus: "Руководство",
        orgStructure: "Редакционная структура",
        members: "Редакционная коллегия",
        interMembers: "Члены международного редакционного совета",
        nationalMembers: "Члены редакционного совета",
        toAuthor: "Авторам статей",
        request: "Требования к статье",
        publish: "Порядок публикации статей в журнале",
        sendArticle: "Отправьте статью в редакцию",
        electronLibrary: "Электронная библиотека",
        subscription: "Подписывайся",
        agreement: "Договор",
        readAgreement: "Ознакомиться с образцом договора",
        downloadAgreement: "Скачать образец договора",
        articles: "Научные статьи",
        publishSubscription: "Подпишитесь на печатное издание журнала",
        electronSubscription: "Подпишитесь на электронную форму журнала",
        f_i_sh: "Ф. И. О.",
        enterName: "Ваша фамилия, имя и отчество",
        address: "Адрес",
        enterAddress: "Введите ваш адрес",
        email: "Электронная почта",
        enterEmail: "Введите адрес вашей электронной почты",
        incorrectEmail: "Введите правильный адрес электронной почты",
        phoneNumber: "Номер телефона",
        enterPhoneNumber: "Введите номер своего телефона",
        incorrectPhoneNumber: "Введите правильный номер телефона",
        enterComment: "Введите ваш комментарий",
        buy: "Купить",
        read: "Читать",
        subscribe: "Подписывайся",
        cancel: "Отмена",
        send: "Отправить",
        enter: "Авторизация",
        password: "Пароль",
        enterPassword: "Введите пароль",
        Singin: "Регистрация",
        lastName: "Фамилия",
        firstName: "Имя",
        enterLastName: "Введите ваша фамилия",
        enterFirstName: "Введите ваше имя",
        forReference: "Введите текст сообщения",
        streetName: "г. Ташкент, ул. Ислама Каримова, 15",
        workTime: "С понедельника по пятницу:",
        article: "Cтатья",
        internationalIndexes: "Международные индексы журнала",
        makeSayt: "Разработчик веб-сайта:",
        legalServices: "Юридические услуги в Ташкенте",
        contact1: "Связаться с нами",
        contact2: "Наш адрес",
        download: "Скачать",
        journalRequests: "Основные требования к статьям",
        journalRequests1: "Статья должна быть отпечатана в текстовом редакторе MS WORD, шрифт Times New Roman, размер шрифта 14, интервал 1,5.",
        journalRequests2: "Текст размещается на странице формата А4 на расстоянии 2 см сверху и снизу, 3 см слева и 1,5 см справа.",
        journalRequests3: "Размер статьи может составлять 6-12 страниц, включая аннотации и ключевые слова.",
        journalRequests4: "Статья не должна содержать орфографических и стилистических ошибок и не являться плагиатом.",
        journalRequests5: "Рекомендуется, чтобы количество сносок было от 8 до 30. Научная статья должна ссылаться как минимум на 4 источника, опубликованных за последние 15 лет.",
        journalRequests6: "Заглавие темы пишется прописными буквами и жирным шрифтом, после него указываются ставится Ф.И.О автора (ов) и место работы (учебы) полностью (без сокращений), а также ученое звание и ученая степень. Вся информация приводится на узбекском, русском и английском языках.",
        journalRequests7: "Аннотация должна быть написана на узбекском, русском и английском языках (размер шрифта 12, интервал 1,15). В аннотации приводится информация о классификации темы, цели работы и достигнутых результатах, научной новизне работы. Количество слов должно быть в пределах 50-200 слов.",
        journalRequests8: "Ключевые слова и термины даются на трех языках: узбекском, русском и английском, рекомендуется не менее пяти ключевых слов.",
        journalRequests9: "Текст статьи должен быть выровнен по ширине.",
        journalRequests10: "Ссылки на использованные источники размещаются внизу страницы.",
        journalRequests11: "Таблицы обозначаются словом «таблица» и нумеруются арабскими цифрами. После названия таблицы точка не ставится.",
        journalRequests12: "Автор несет ответственность за достоверность, правильность информации и доказательств, а также отсутствие плагиата в статье.",
        journalRequests13: "В конце информационного письма о регистрации приводится ОБРАЗЕЦ. Рекомендуется использовать этот образец.",
        journalRequests14: "Материалы, не соответствующие этим требованиям, не принимаются к публикованию в журнале.",
        markFile: "Выберите нужный файл",
        chooseLanguages: "Выберите язык",
        helpVideo: "Видеоурок по проверке номер DOI",
        founder: "Учредитель:",
        founder2: "Соучредители:",
        allJournals: "НОМЕРА ЖУРНАЛА",
        tradeUnions: " Федерация профсоюзы Узбекистана",
        ombudsman: "Уполномоченный олий мажлиса республики узбекистан по правам человека (Омбудсман)",
        payments: "Оплаты",
        subscribers: "Подписчики",
        uploadFile: "Загрузить",
        uploadFileHeader: "Загрузить новый выпуск журнала",
        year: "год",
        year2: "Год",
        release: "Выпуск",
        imageFile: "Файл рисунки обложки",
        pdfFile: "Файл PDF журнала",
        coverPdfFile: "Файл PDF (содержание)",
        logout: "Выход",
        save: "Сохранить",
        payment: "Оплата",
        edit: "Редактировать",
        autobiography: "Автобиография",
        chairman: "Председатель редакционного совета",
        mainEditor: "Главный редактор",
        leadership: "Руководство",
        akmalSaidov: "Акмал Саидов",
        akmalSaidov2: "Саидов Акмал Холматович",
        akmalSaidovDesc: "первый заместитель Спикера Законодательной палаты Олий Мажлиса Республики Узбекистан, директор Национального центра Республики Узбекистан по правам человека, действительный член Академии наук Республики Узбекистан, доктор юридических наук, профессор.",
        erkinErnazarov: "Эрназаров Эркин Мадиёрович",
        erkinErnazarovDesc: "Заслуженный журналист Республики Узбекистан",
        tanzilaNarbayeva: "Танзила Нарбаева",
        tanzilaNarbayevaDesc: "Председатель Сената Олий Мажлиса Республики Узбекистан.",
        qudratillaRafiqov: "Кудратилла Рафиков",
        qudratillaRafiqovDesc: "Председатель Федерации профсоюзов Республики Узбекистан. ",
        halimBoboyev: "Халим Бобоев",
        halimBoboyevDesc: "Заслуженный деятель науки Республики Узбекистан, профессор Ташкентского государственного юридического университета, доктор юридических наук.",
        mixailGasanov: "Михаил Гасанов",
        mixailGasanovDesc: "Доцент Ташкентского государственного юридического университета, кандидат юридических наук. ",
        mirzatilloTillabayev: "Мирзатилло Тиллабаев",
        mirzatilloTillabayevDesc: "Первый заместитель директора Национального центра Республики Узбекистан по правам человека, доктор юридических наук, доцент.",
        omonOqyulov: "Омон Окюлов",
        omonOqyulovDesc: "Заслуженный юрист Республики Узбекистан, главный научный сотрудник Института государства и права Академии наук, профессор Ташкентского государственного юридического университета, доктор юридических наук.",
        asqarGafurov: "Aскар Гафуров",
        asqarGafurovDesc: "Заместитель председателя Конституционного суда Республики Узбекистан, кандидат юридических наук, доцент.",
        muzaffarMamasiddiqov: "Музаффар Мамасиддиков",
        muzaffarMamasiddiqovDesc: "Заместитель начальника Академии Генеральной прокуратуры Республики Узбекистан, доктор юридических наук, профессор.",
        elizavetaKutibayeva: "Елизавета Кутибаева",
        elizavetaKutibayevaDesc: "Декан юридического факультета Каракалпакского государственного университета имени Бердаха, доктор юридических наук, профессор.",
        haydaraliMuxamedov: "Хайдарали Мухамедов",
        haydaraliMuxamedovDesc: "Профессор Ташкентского государственного юридического университета, кандидат юридических наук. ",
        aslan: "Аслан Абашидзе",
        aslanContry: "(Российская Федерация)",
        aslanDesc: "Заведующий кафедрой международного права Российского университета дружбы народов, доктор юридических наук, профессор.",
        akira: "Акира Фужимото",
        akiraContry: "(Япония)",
        akiraDesc: "Директор Центра по Азиатскому правовому обмену Университета Нагоя, доктор юридических наук, профессор.",
        uilyam: "Уильям Батлер",
        uilyamCountry: "(США)",
        uilyamDesc: "Почетный профессор Дикинсонской юридической школы при Пенсильванском университете и Лондонского университета, доктор юридических наук.",
        bodio: "Тадеуш Бодио",
        bodioCountry: "(Польша)",
        bodioDesc: "Профессор Варшавского университета, доктор философских наук. ",
        margub: "Маргуб Салим Бутт",
        margubCountry: "(Организация исламского сотрудничества)",
        margubDesc: "Исполнительный директор секретариата Независимой постоянной комиссии по правам человека Организации исламского сотрудничества.",
        reyn: "Рейн Мюллерсон",
        reynCountry: "(Эстония)",
        reynDesc: "Почетный профессор Таллинского университета, доктор юридических наук.",
        tatyana: "Татьяна Николаевна Москалькова",
        tatyanaCountry: "(Российская Федерация)",
        tatyanaDesc: "Уполномоченный по правам человека Российской Федерации, доктор юридических наук, профессор.",
        sergey: "Сергей Ударцев",
        sergeyCountry: "Казакстан",
        sergeyDesc: "Директор Научно-исследовательского института правовой политики и конституционного законодательства Казахского государственного юридического университета, доктор юридических наук, профессор.",
        content: "Содержание",
        typeElectron: "Электронный вид",
        typePrinted: "В печатном виде",
        years: "год",
        years2: "Годы",
        releases: "Выпуски журнала: ",
        release_1: "1-выпуск",
        release_2: "2-выпуск",
        release_3: "3-выпуск",
        release_4: "4-выпуск",
        buyCount: "Количество",
        selectedReleases: "Выбранные выпуски",
        aboutJournal1: "О журнале «ДЕМОКРАТИЗАЦИЯ И ПРАВА ЧЕЛОВЕКА»",
        aboutJournal2: "На основании Указа Президента Республики Узбекистан от 31 октября 1996 года «О создании Национального центра Республики Узбекистан по правам человека» создан журнал«Демократизация и права человека».",
        aboutJournal3: "Журнал издается ежеквартально на узбекском, английском и русском языках.",
        aboutJournal4: "Журнал внесен в список научных изданий Высшей аттестационной комиссии при Кабинете Министров Республики Узбекистан, рекомендованных для публикаций основных научных результатов диссертаций по:",
        aboutJournal5: "политическим;",
        aboutJournal6: "правовым;",
        aboutJournal7: "философским;",
        aboutJournal8: "историческим;",
        aboutJournal9: "исламоведческим;",
        aboutJournal10: "социологическим наукам.",
        aboutJournal11: "В журнале «Демократизация и права человека» было опубликовано 2134 научных статей. Из них 1889 статей опубликованы отечественными авторами, 245 - зарубежными авторами.",
        publish1: "ПРОЦЕДУРА ВЫПУСКА СТАТЬИ В ЖУРНАЛЕ:",
        publish2: "1. Авторам, в первую очередь, следует ознакомиться с требованиями к статье и оформить статью с учетом этих требование.",
        publish3: "2. Статья должна быть в области политологии, а также права, философии, истории, исламоведения и социологии. Рекомендуется освещать вопросы прав человека.",
        publish4: "3. Принимаются оригинальные и ранее не опубликованные статьи, на узбекском, русском или английском языках.",
        publish5: "4.\tСтатью можно отправлять следующими способами:",
        publish6: "•\tАвторы могут прийти в редакцию и подать статью в электронном и бумажном виде.",
        publish7: "•\tМожно отправить на электронную почту journal@nhrc.uz.",
        publish8: "•\tМожно отправить через веб-сайт журнала.",
        publish9: "5.\tСтатьи, поступающие в редакцию, проверяются на соответствие требованиям журнала. Статьи, соответствующие требованиям, принимаются на основании регистрации.",
        publish10: "6.\tРедакционная коллегия, анонимно рассмотрев статью, дает заключения.",
        publish11: "7.\tНа основании заключения редколлегии статья будет размещена на сайте журнала или отклонена. Лучшие статьи на сайте также будут опубликованы в журнале традиционного формата.",
        publish12: "8.\tРедакция оставляет за собой право сокращать, исправлять и редактировать статьи.",
        publish13: "9.\tСтатьи, не отвечающие требованиям, редакционной коллегией не рассматриваются и автору не возвращаются.",
        news: "Новости",
        doiNumber: "Номер DOI: ",
        articleDate: "Дата: ",
        downloadSamples: "Скачать образцы",
        previous: "Предыдущий",
        next: "Следующий",
        articleList: "Список статей",
        journalList: "Список журналов",
        fileList: "Список файлов",
        search: "Поиск",
        yourFeedbackSent: "Ваш отзыв отправлен в редакцию",
        errorWhenSentYourFeedback: "При отправке отзыва произошла ошибка"
    },
    en: {
        app_title:"Title",
        language:"Language",
        organizationName:"The National Centre for Human Rights of the Republic of Uzbekistan",
        journalName1:"DEMOCRATIZATION AND HUMAN RIGHTS",
        journalName2:"scientific and educational journal",
        phrase:"The people should serve our people, not government agencies.",
        shavkatMirziyoyev:"Shavkat MIRZIYOYEV",
        home:"Home",
        aboutJournal:"About the journal",
        allInformation1:"General information",
        allInformation2:"JOURNAL OF DEMOCRATIZATION AND HUMAN RIGHTS Scientific-educational journal. Journal March 16, 2020 Registered with the Information and Mass Communications Agency under the Presidential Administration of the Republic of Uzbekistan under number 0202. The journal 'Democratization and Human Rights' is included in the list of scientific publications recommended for publication by the Higher Attestation Commission under the Cabinet of Ministers of the Republic of Uzbekistan on the main scientific results of dissertations in law, philosophy, history, politics, Islamic studies and sociology",
        litsenziya:"Certificate",
        aboutus:"Leadership",
        orgStructure:"Editorial structure",
        members:"Journal management",
        interMembers:"Members of the International Editorial Board",
        nationalMembers:"Members of the Editorial Board",
        toAuthor:"Submissions",
        request:"Requirements for articles",
        publish:"The order of publication of articles in the journal ",
        sendArticle:"Submit an article",
        electronLibrary:"Electron Library",
        subscription:"Subscribe",
        agreement:"Agreement",
        readAgreement: "Get acquainted with the sample contract",
        downloadAgreement: "Download a sample contract",
        articles:"Scientific articles",
        publishSubscription:"Subscribe to the traditional (paper) journal format",
        electronSubscription:"Subscribe to the electronic from of the journal",
        f_i_sh:"Name",
        enterName:"",
        address:"Address",
        enterAddress:"Enter your address",
        email:"E-mail",
        enterEmail:"Enter your email",
        incorrectEmail: "Entered email is incorrect",
        phoneNumber:"Phone number",
        enterPhoneNumber:"Enter your phone number",
        incorrectPhoneNumber: "Entered phone number is incorrect",
        enterComment: "Enter your comment",
        buy:"Purchase",
        read:"Read",
        subscribe:"Subscribe",
        cancel:"Cancel",
        send:"Send",
        enter:"Enter",
        password:"Password",
        enterPassword:"Enter your password",
        Singin:"Registration",
        lastName:"Last name",
        firstName:"First name",
        enterLastName:"Enter your last name",
        enterFirstName:"Enter your first name",
        forReference:"Article",
        streetName:"Tashkent st, Islom Karimov street, 15",
        workTime:"Monday - Friday:",
        article:"Article",
        internationalIndexes:"International indexes of the journal",
        makeSayt:"Site creator:",
        legalServices:"Legal services in Tashkent",
        contact1:"Contact us",
        contact2:"Our address",
        download:"Download",
        journalRequests:"Requirements for article",
        journalRequests1:"The article should be in MS WORD text editor, Times New Roman font, 14 font size, 1.5 spacing.",
        journalRequests2:"The text should be placed on an A4 page, 2 cm from the top and bottom, 3 cm from the left, 1.5 cm from the right.",
        journalRequests3:"The size of the article can be 6-12 pages, including annotations and keywords.",
        journalRequests4:"The article should be free from spelling and stylistic errors and should not be plagiarized.",
        journalRequests5:"It is recommended that the number of references be at least 8 and at most 30. The scientific article should refer to at least 4 sources published in the last 15 years.",
        journalRequests6:"The title of the topic is in capital letters and in bold, followed by the author's name and place of work (study) in full (without abbreviations), as well as academic title and degree. All information must be in Uzbek, Russian, English.",
        journalRequests7:"The annotation should be written in Uzbek, Russian and English (12 font size, 1.15 spacing). The annotation provides information on the classification of the topic, the purpose of the work and the results achieved, the scientific novelty of the work. The number of words should be in the range of 50-200 words.",
        journalRequests8:"Keywords and terms are given in three languages: Uzbek, Russian and English and it is recommended to have at least 5.",
        journalRequests9:"The text of the article should be flattened.",
        journalRequests10:"Footnotes to the article should be provided at the bottom of the page. ",
        journalRequests11:"Tables are represented by the word \"table\" and are denoted by Arabic numerals. A full stop is placed after the table name and placed to the right.",
        journalRequests12:"The author is responsible for the accuracy, correctness of the information and evidence in the article and the absence of plagiarism.",
        journalRequests13:"At the end of the information letter on registration, a SAMPLE is given. It is recommended to use this sample.",
        journalRequests14:"Materials that do not meet these requirements will not be accepted for publication in the journal.",
        markFile:"Select the desired file",
        chooseLanguages:"Select a language",
        helpVideo:"Video tutorial to check Doi number",
        founder:"Founder:",
        founder2:"Co-founders:",
        allJournals:"ISSUES OF THE JOURNAL",
        tradeUnions:"Uzbekistan trade unions Federation",
        ombudsman:"Higher Education of the Republic of Uzbekistan Assembly on Human Rights Representative (Ombudsman)",
        payments:"Payments",
        subscribers:"Subscribers",
        uploadFile:"Upload",
        uploadFileHeader:"Upload a new release of journal",
        year:"year",
        year2:"Year",
        release:"Release",
        imageFile:"Cover image file",
        pdfFile:"Journal PDF file",
        coverPdfFile: "PDF file (Сontent)",
        logout:"Logout",
        save:"Save",
        payment:"Payment",
        edit:"Edit",
        autobiography:"Autobiography",
        chairman:"Chairman of the Editorial Board",
        mainEditor:"Editor-in-Chief",
        leadership:"Administration",
        akmalSaidov:"Akmal Saidov",
        akmalSaidov2:"Saidov Akmal Kholmatovich",
        akmalSaidovDesc:"First Deputy Speaker of the Legislative Chamber of the Oliy Majlis of the Republic of Uzbekistan, Director of the National Center for Human Rights, a full member of the Academy of Sciences of the Republic of Uzbekistan, Doctor of Law, Professor.",
        erkinErnazarov:"Ernazarov Erkin Madiyorovich",
        erkinErnazarovDesc:"Honored Journalist of the Republic of Uzbekistan",
        tanzilaNarbayeva:"Tanzila Narbayeva",
        tanzilaNarbayevaDesc:"Chairperson of the Senate of the Oliy Majlis of the Republic of Uzbekistan.",
        qudratillaRafiqov:"Kudratilla Rafikov",
        qudratillaRafiqovDesc:"Chairman of the Federation of Trade Unions of Uzbekistan.",
        halimBoboyev:"Khalim Boboyev",
        halimBoboyevDesc:"Honored Scientist of the Republic of Uzbekistan, Professor of Tashkent State Law University, Doctor of Law.",
        mixailGasanov:"Mikhail Gasanov",
        mixailGasanovDesc:"Associate Professor of Tashkent State Law University, Candidate of Law.",
        mirzatilloTillabayev:"Mirzatillo Tillabayev",
        mirzatilloTillabayevDesc:"First Deputy Director of the National Center for Human Rights of the Republic of Uzbekistan, Doctor of Law, Associate Professor.",
        omonOqyulov:"Omon Okyulov",
        omonOqyulovDesc:"Honored Lawyer of the Republic of Uzbekistan, Chief Research Fellow of the Institute of State and Law, Professor of Tashkent State Law University, Doctor of Law.",
        asqarGafurov:"Askar Gafurov",
        asqarGafurovDesc:"Deputy Chairman of the Constitutional Court of the Republic of Uzbekistan, Candidate of Law, Associate Professor.",
        muzaffarMamasiddiqov:"Muzaffar Mamasiddikov",
        muzaffarMamasiddiqovDesc:"Deputy Head of the Academy of the Prosecutor General's Office of the Republic of Uzbekistan, Doctor of Law, Professor.",
        elizavetaKutibayeva:"Yelizaveta Kutibayeva",
        elizavetaKutibayevaDesc:"Dean of the Faculty of Law, Karakalpak State University named after Berdakh, Doctor of Law, Professor.",
        haydaraliMuxamedov:"Khaydarali Mukhamedov",
        haydaraliMuxamedovDesc:"Professor of Tashkent State Law University, Candidate of Law.",
        aslan:"Aslan Abashidze",
        aslanContry:"(Russian Federation)",
        aslanDesc:"Head of the Department of International Law, Peoples' Friendship University of Russia, Doctor of Law, Professor.",
        akira:"Akira Fujimoto",
        akiraContry:"(Japan)",
        akiraDesc:"Director of the Asian Legal Exchange Center, Nagoya University, Doctor of Law, Professor.",
        uilyam:"William Butler",
        uilyamCountry:"(USA)",
        uilyamDesc:"An honorary professor at the Dickinson School of Law at the University of Pennsylvania and the University of London, Legum Doctor (LL.D.).",
        bodio:"Tadeush Bodio",
        bodioCountry:"(Poland)",
        bodioDesc:"Professor of the University of Warsaw, Doctor of Philosophy.",
        margub:"Marghoob Saleem Butt",
        margubCountry:"(Organization of Islamic Cooperation)",
        margubDesc:"The Executive Director of the Secretariat of the Independent Permanent Commission on Human Rights of the Organization of Islamic Cooperation.",
        reyn:"Rein Mullerson",
        reynCountry:"(Estonia)",
        reynDesc:"An honorary professor at Tallinn University, Doctor of Law.",
        tatyana:"Tatyana Moskalkova ",
        tatyanaCountry:"(Russian Federation)",
        tatyanaDesc:"Representative of the Russian Federation for Human Rights, Doctor of Law, Professor.",
        sergey:"Sergey Udarsev",
        sergeyCountry:"Kazakhstan",
        sergeyDesc:"Director of the Research Institute of Legal Policy and Constitutional Legislation of the Humanities and Law University of Kazakhstan, Doctor of Law, Professor.",
        content:"Content",
        typeElectron:"Electron type",
        typePrinted:"Traditional form",
        years:"year",
        years2:"Years",
        releases:"Journal releases: ",
        release_1:"1-release",
        release_2:"2-release",
        release_3:"3-release",
        release_4:"4-release",
        buyCount:"Count",
        selectedReleases:"Selected releases",
        aboutJournal1:"ABOUT THE SCIENTIFIC-EDUCATIONAL JOURNAL\n\"DEMOCRACY AND HUMAN RIGHTS\"",
        aboutJournal2:"On January 28, 1999, the journal “Democratization and Human Rights” was registered as a media outlet on the basis of the President of the Republic of Uzbekistan's Decree dated October 31, 1996 “On the Establishment of the National Center for Human Rights” and that year, the journal's first issue was published.",
        aboutJournal3:"Founder of the journal: National Center of the Republic of Uzbekistan for Human Rights; \n" +
            "Co-founders of the journal since 2006: The Authorized Person of the Oliy Majlis for Human Rights (Ombudsman) and the Federation of Trade Unions of Uzbekistan.",
        aboutJournal4:"The journal is published quarterly in Uzbek, English and Russian. Today the journal is included in the list of scientific publications by the Higher Attestation Commission under the Cabinet of Ministers of the Republic of Uzbekistan, recommended for publication of the main scientific results of dissertations:",
        aboutJournal5:"political sciences; ",
        aboutJournal6:"jurisprudence;",
        aboutJournal7:"philosophy;",
        aboutJournal8:"history;",
        aboutJournal9:"Islamic studies;",
        aboutJournal10:"sociological sciences.",
        aboutJournal11:"More than ten prestigious worldwide indices include the journal. The journal “Democratization and Human Rights” published 2134 scholarly papers between 1999 and 2021. Uzbek authors published 1889 papers, while foreign authors published 245 articles.",
        publish1:"THE ORDER OF PUBLICATION OF ARTICLES IN THE JOURNAL:",
        publish2:"1. Authors should, first of all, get acquainted with the requirements for the article and write an article based on it.",
        publish3:"2. The article should be in the field of political science, as well as law, philosophy, history, Islamic studies and sociology. It is recommended that human rights issues be covered.",
        publish4:"3. Articles are accepted in one of the original, previously unpublished, Uzbek, Russian or English languages.",
        publish5:"4. The article can be sent to the editor in 3 ways:",
        publish6:"•  Authors can submit articles in electronic and paper forms to the editorial office.",
        publish7:"•  through e-mail journal@nhrc.uz.",
        publish8:"•  through the website of the journal.",
        publish9:"5. Articles submitted to the editorial office are checked for compliance with the requirements of the journal, articles that meet the requirements are accepted on the basis of registration.",
        publish10:"6. The Editorial Board anonymously considers accepted articles and gives conclusion.",
        publish11:"7. Based on the conclusion of the Editorial Board, the article will be posted on the journal's website or rejected. The best of the articles on the website will also be published in a traditional format journal.",
        publish12:"8. The editors have the right to shorten, correct and edit articles.",
        publish13:"9. Articles that do not comply with the established procedure, do not correspond to the topic and direction will not be considered by the Editorial Board and will not be returned to the author.",
        news:"News",
        doiNumber:"DOI number: ",
        articleDate:"Date: ",
        downloadSamples:"Download samples",
        previous:"Previous",
        next:"Next",
        articleList:"List of articles",
        journalList: "List of journals",
        fileList: "List of files",
        search: "Search",
        yourFeedbackSent: "Your feedback has been sent to the editors",
        errorWhenSentYourFeedback: "An error occurred while submitting your feedback"
    }
});
strings.setLanguage('uz');

export function getKey() {
    return Math.random() * 100000000000000000;
}

export function getUser() {
    let item = localStorage.getItem('dhruser');
    if (item !== undefined && item !== null) {
        return JSON.parse(item);
    }
    return null;
}

export function isAdmin(user) {
    return user !== undefined && user !== null && user.roles.includes('ADMIN');
}


export function logout() {
    localStorage.removeItem('dhruser');
    window.open(publicResourceURL, '_self');
}

export default class Utils extends React.Component {
}