import {strings} from "../Utils";

export default function InternationalIndex() {
    return (
        <div>
            <div className="indexs p-0">
                <div className="text-center py-4">
                    <h4>{strings.internationalIndexes}</h4>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 ">
                        <a target="_blank" rel="noreferrer" href="https://www.doi.org/"> <img src="../images/doi.gif" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    {/*<div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 ">
                        <img src="https://assets.crossref.org/logo/crossref-logo-landscape-200.svg" width="160" height="68" alt="Crossref logo"/>
                    </div>*/}
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 p-0">
                        <a target="_blank" rel="noreferrer" href="https://orcid.org/0000-0003-2625-5709"> <img src="../images/orcid.svg" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4">
                        <a target="_blank" rel="noreferrer" href="https://scholar.google.com/citations?hl=ru&user=pZh3z8wAAAAJ">
                            <img src="../images/logoGoogle.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 p-0">
                        <a target="_blank" rel="noreferrer" href="https://elibrary.ru/title_about_new.asp?id=76570"><img src="../images/elibrary.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>


                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 ">
                        <a target="_blank" rel="noreferrer" href="https://www.researchbib.com/?action=viewMyProfile">
                            <img src="../images/logo1.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 p-0">
                        <a target="_blank" rel="noreferrer" href="http://journal-index.org/index.php/asi/user/index">
                            <img src="../images/logo2.jpg" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4">
                        <a target="_blank" rel="noreferrer" href="https://journals.indexcopernicus.com/search/details?id=69054">
                            <img src="../images/logo3.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 p-0">
                        <a target="_blank" rel="noreferrer" href="https://www.worldcat.org/search?qt=worldcat_org_all&q=2181-6409">
                            <img src="../images/logo4.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 ">
                        <a target="_blank" rel="noreferrer" href="https://cyberleninka.ru/donate?utm_source=cyberleninka.ru&utm_medium=menu"><img src="../images/ciberlinika.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 p-0">
                        <a target="_blank" rel="noreferrer" href="http://www.sjifactor.com/invoice.php?id=2181-6409">
                            <img src="../images/logo5.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4">
                        <a target="_blank" rel="noreferrer" href="http://globalimpactfactor.com/thank-you/">
                            <img src="../images/logoGlobal.jpg" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4 p-0">
                        <a target="_blank" rel="noreferrer" href="https://impactfactorservice.com/home/journals_list">
                            <img src="../images/iifs.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                    <div className="col-xl-6 col-lg-3 col-md-4 col-6 mt-4">
                        <a target="_blank" rel="noreferrer" href="https://www.researchgate.net">
                            <img src="../images/researchgate.png" alt="" width={"100%"} height={"100%"}/></a>
                    </div>
                </div>
            </div>
            <div className="infor">
                <div className="py-5 row">
                    {/*<div className="doiNumbers col-xl-12 col-lg-6 col-md-6 pt-4    ">
                        <p>DOI number:10.5281/00000.4674153</p>
                        <p>DOI url: <br/><a target="_blank" rel="noreferrer" href="https://doi.org/10.5281/zenodo.4674153">
                            https://doi.org/000000.4674153</a></p>
                        <p>DOI ZENADO : 10.5281/zenodo.4674153</p>
                        <p>DOI ZENADO url:<br/>
                            <a target="_blank" rel="noreferrer" href="https://doi.org/10.5281/zenodo.4674153">https://doi.org/10.5281/zenodo.4674153</a>
                        </p>
                    </div>*/}
                    <div className="Helpvideo col-xl-12  col-lg-6 col-md-6">
                        <h4> {strings.helpVideo}</h4>
                        <video width="100%" controls>
                            <source src="../videos/helpVideo.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
        </div>
    )
}