import {strings} from "../Utils";

export default function Founders() {
    return (
        <div className="text-center">
            <h3>{strings.founder}</h3>
            <div className="row mb-3">
                <div className=" col-sm-6 col-xl-12 ">
                    <div className="text-center pt-3 ">
                        <a className="w-100" target="_blank" rel="noreferrer" href="http://insonhuquqlari.uz/">
                            <img src="../images/brend.png" alt="" width="50%"/>
                        </a>
                    </div>
                </div>
            </div>
            <h3>{strings.founder2}</h3>
            <div className="row">
                <div className=" col-sm-6 col-xl-12 ">
                    <div className="text-center pt-3 ">
                        <a className="w-100" target="_blank" rel="noreferrer" href="https://kasaba.uz/"> <img
                            src="../images/kasaba.png" alt="" width="60%"/>
                        </a>
                    </div>
                </div>
                <div className="  col-sm-6 col-xl-12 ">
                    <div className="text-center  pt-4">
                        <a className="w-100" target="_blank" rel="noreferrer" href="http://www.ombudsman.uz/uz"> <img
                            src="../images/ombudsman.jpg" alt="" width="60%"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

}