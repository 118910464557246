import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Menu from './components/Menu';
import Footer from "./components/Footer";
import {withRouter} from "react-router-dom";
import {strings} from "./Utils";
import React from "react";

class App extends React.Component {
    constructor() {
        super();
        let locale = localStorage.getItem('locale') || 'uz';
        this.state = {
            language: locale
        };
        strings.setLanguage(locale);
    }

    changeLanguage(lang) {
        this.setState({
            language: lang
        });
        console.log(lang);
        strings.setLanguage(lang);
        localStorage.setItem("locale", lang);
    }

    render() {
        return (
            <>
                <Header/>
                <Menu changeLanguage={this.changeLanguage.bind(this)}/>
                <Footer/>
            </>

        )
    }
}

export default withRouter(App);
