import "./App.css";
import React from "react";
import UserList from "./pages/userList/UserList";
import {getUser, logout} from "./Utils";

export default class AdminHomeView extends React.Component {

    render() {
        let user = getUser();
        if (user === undefined || user === null) {
            logout();
        }

        return (
            <div className="container-fluid ">
                <UserList/>
            </div>
        )
    }
}
