import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

function Litsenziya () {
    return(
        <div className="container  text-center">
            <img src="./images/guvohnoma.jpg" alt="" width={"100%"} className="px-5 pt-5  align-items-center justify-content-center"/>
            <a target="_blank" className="btn btn-primary px-4 my-5 " href="./files/guvohnoma.pdf">{strings.download}</a>
        </div>
    )
    
}

export  default withRouter(Litsenziya);