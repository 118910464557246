import React from "react";
import axios from "axios";
import {apiURL, strings} from "../Utils";
import swal from "sweetalert";
import Input from 'react-phone-number-input/input';
import {headers} from "./config";

export default class BuyJournalView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptionType: '',
            showFields: false,
            subscriberId: 0,
            totalQuantity: 0,
            buyerLastName: '',
            buyerFirstName: '',
            buyerAddress: '',
            buyerEmail: '',
            buyerPhoneNumber: '',
            yearReleases: '',
            years: [],
            selectedReleases: [],
            selectedReleaseWidgets: []
        };
        this.setPhone = this.setPhone.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount() {
        let years = [];
        for (let i=2022; i>=1999; i--) {
            years.push(<option key={'year_' + i} value={i}>{i}</option>);
        }
        this.setState({
            years: years
        })
    }

    buyJournal() {
        let lastName = this.state.buyerLastName;
        let firstName = this.state.buyerFirstName;
        let phoneNumber = this.state.buyerPhoneNumber;
        if (phoneNumber === null || phoneNumber === '') {
            swal(strings.enterPhoneNumber, '', 'error');
            return;
        } else if (phoneNumber.length < 13 || phoneNumber.length > 13) {
            swal(strings.incorrectPhoneNumber, '', 'error');
            return;
        }
        let data = new FormData();
        data.append('lastName', lastName);
        data.append('firstName', firstName);
        data.append('publishType', this.state.subscriptionType);
        if (this.state.subscriptionType.includes('electronSubs')) {
            let emailAddress = this.state.buyerEmail;
            if (emailAddress === null || emailAddress === '') {
                swal('Emailingizni kiriting', '', 'error');
                return;
            } else if (!emailAddress.includes('@')) {
                swal(strings.incorrectEmail, '', 'error');
                return;
            }
            data.append('email', emailAddress);
            data.append('address', '---');
        } else {
            let address = this.state.buyerAddress;
            if (address === null || address === '' || address.length === 0) {
                swal(strings.enterAddress, '', 'error');
                return;
            }
            data.append('email', '---');
            data.append('address', address);
        }
        data.append('phoneNumber', phoneNumber);
        let totalQuantity = 0;
        let items = this.state.selectedReleases.map(item=>{
            let quantity = document.getElementById(item).value;
            totalQuantity = totalQuantity + Number(quantity);
            return item + '_' + quantity;
        });
        this.setState({
            totalQuantity: totalQuantity
        })
        data.append('journalItems', items);
        axios.post(apiURL + '/api/subscription/buy', data, headers).then(result => {
            if (result.data.statusCode === 200) {
                this.setState({
                    subscriberId: result.data.data
                });
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    };

    setPhone(e) {
        this.setState({
            buyerPhoneNumber: e
        })
    }

    setValue(e) {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    setSubscriptionType(value) {
        this.setState({
            showFields: true,
            subscriptionType: value
        })
    }

    selectRelease(e) {
        let releaseChecked = e.target.checked;
        let year = document.getElementById('years').value;
        let release = e.target.id.replaceAll('release_', '');
        let yearRelease = year + '_' + release;
        let yearReleases = this.state.yearReleases;
        let selectedReleases = this.state.selectedReleases;
        let widgets = this.state.selectedReleaseWidgets;
        if (!yearReleases.includes(yearRelease + ',')) {
            widgets.push(<tr><td>{year + '-yil ' + release + '-son'}</td>
                <td><input id={yearRelease} className="form-control selectJItem" defaultValue={1}/></td></tr>);
            selectedReleases.push(yearRelease);
            yearReleases = yearReleases + yearRelease + ',';
            this.setState({
                yearReleases: yearReleases,
                selectedReleases: selectedReleases,
                selectedReleaseWidgets: widgets
            })
        } else {
            if (!releaseChecked) {
                widgets = [];
                yearReleases = '';
                let newReleases = [];
                for (let i=0; i<selectedReleases.length; i++) {
                    if (selectedReleases[i] !== yearRelease) {
                        let sp = selectedReleases[i].split('_');
                        widgets.push(<tr><td>{sp[0] + '-yil ' + sp[1] + '-son'}</td>
                            <td><input className="form-control selectJItem" defaultValue={1}/></td></tr>);
                        newReleases.push(selectedReleases[i]);
                        yearReleases = yearReleases + selectedReleases[i] + ',';
                    }
                }
                this.setState({
                    yearReleases: yearReleases,
                    selectedReleases: newReleases,
                    selectedReleaseWidgets: widgets
                })
            }
        }
    }

    onYearChange() {
        document.getElementById('release_1').checked = false;
        document.getElementById('release_2').checked = false;
        document.getElementById('release_3').checked = false;
        document.getElementById('release_4').checked = false;
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="myModal2">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>{strings.buy}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="form-group w-100 d-flex">
                                    <div className="form-check m-2">
                                        <input className="form-check-input" type="radio" name="subscriptionType"
                                               id="electronSubs"
                                               value="option1"
                                               defaultChecked={this.state.subscriptionType.includes('electronSubs')}
                                               onChange={this.setSubscriptionType.bind(this, 'electronSubs')}/>
                                        <label className="form-check-label" htmlFor="electronSubs">{strings.typeElectron}</label>
                                    </div>
                                    <div className="form-check m-2">
                                        <input className="form-check-input" type="radio" name="subscriptionType"
                                               id="printedSubs"
                                               value="option2"
                                               defaultChecked={this.state.subscriptionType.includes('printedSubs')}
                                               onChange={this.setSubscriptionType.bind(this, 'printedSubs')}/>
                                        <label className="form-check-label" htmlFor="printedSubs">{strings.typePrinted}</label>
                                    </div>
                                </div>
                                {this.state.showFields &&
                                <div>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="buyerLastName">{strings.lastName}</label>
                                            <input type="text" className="form-control" placeholder={strings.enterLastName}
                                                   id="buyerLastName" onChange={this.setValue}/>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="buyerFirstName">{strings.firstName}</label>
                                            <input type="text" className="form-control" placeholder={strings.enterFirstName}
                                                   id="buyerFirstName" onChange={this.setValue}/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        {this.state.subscriptionType.includes('printedSubs') &&
                                        <div className="col-md-6">
                                            <label htmlFor="buyerAddress">{strings.address}</label>
                                            <input type="text" className="form-control" placeholder={strings.enterAddress}
                                                   id="buyerAddress" onChange={this.setValue}/>
                                        </div>
                                        }
                                        {this.state.subscriptionType.includes('electronSubs') &&
                                        <div className="col-md-6">
                                            <label htmlFor="buyerEmail">{strings.email}</label>
                                            <input type="text" className="form-control" placeholder={strings.enterEmail}
                                                   id="buyerEmail" onChange={this.setValue}/>
                                        </div>
                                        }
                                        <div className="col-md-6">
                                            <label htmlFor="buyerPhoneNumber">{strings.phoneNumber}</label>
                                            <Input international withCountryCallingCode defaultCountry="UZ"
                                                   country="UZ" onChange={this.setPhone} maxLength={17}
                                                   className="form-control" id="buyerPhoneNumber"/>
                                        </div>
                                    </div>
                                    <div className="form-group w-100">
                                        <label htmlFor="years">{strings.years}</label>
                                        <select className="form-control" id="years" onChange={this.onYearChange.bind(this)}>
                                            {this.state.years}
                                        </select>
                                    </div>
                                    <div className="form-group w-100 d-flex">
                                        <label htmlFor="releases">{strings.releases}</label>
                                        <div className="form-check ml-2">
                                            <input className="form-check-input" type="checkbox" value="" id="release_1" onChange={this.selectRelease.bind(this)}/>
                                            <label className="form-check-label" htmlFor="release_1">
                                                {strings.release_1}
                                            </label>
                                        </div>
                                        <div className="form-check ml-2">
                                            <input className="form-check-input" type="checkbox" value="" id="release_2" onChange={this.selectRelease.bind(this)}/>
                                            <label className="form-check-label" htmlFor="release_2">
                                                {strings.release_2}
                                            </label>
                                        </div>
                                        <div className="form-check ml-2">
                                            <input className="form-check-input" type="checkbox" value="" id="release_3" onChange={this.selectRelease.bind(this)}/>
                                            <label className="form-check-label" htmlFor="release_3">
                                                {strings.release_3}
                                            </label>
                                        </div>
                                        <div className="form-check ml-2">
                                            <input className="form-check-input" type="checkbox" value="" id="release_4" onChange={this.selectRelease.bind(this)}/>
                                            <label className="form-check-label" htmlFor="release_4">
                                                {strings.release_4}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <fieldset>
                                            <legend>{strings.selectedReleases}</legend>
                                            <table className="table table-bordered w-100">
                                                <thead>
                                                <tr>
                                                    <th>{strings.selectedReleases}</th>
                                                    <th>{strings.buyCount}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.selectedReleaseWidgets}
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>
                                </div>
                                }
                            </div>

                            <div className="modal-footer justify-content-center d-flex">
                                <button type="button" className="btn btn-success"
                                        onClick={this.buyJournal.bind(this)}>{strings.buy}</button>

                                {(this.state.subscriberId !== undefined && this.state.subscriberId !== null && Number(this.state.subscriberId) > 0) &&
                                <form id="clickForm" action="https://my.click.uz/services/pay" method="get"
                                      target="_blank">
                                    <input type="hidden" name="service_id" value="17823"/>
                                    <input type="hidden" name="merchant_id" value="12918"/>
                                    <input type="hidden" name="merchant_user_id" value="23808"/>
                                    <input type="hidden" name="amount" value={this.state.totalQuantity * 30000}/>
                                    <input type="hidden" name="transaction_param" value={this.state.phoneNumber}/>
                                    <input type="hidden" name="return_url" value="http://dhrjournal.uz/api/subscription/payment/click"/>
                                    <input type="submit" className="btn btn-info" value="Clickdan to`lov"/>
                                </form>
                                }

                                <button type="button" className="btn btn-danger"
                                        data-dismiss="modal">{strings.cancel}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}