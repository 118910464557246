import React from "react";
import axios from "axios";
import {apiURL, getKey, strings} from "../Utils";
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import swal from "sweetalert";
import {headers, headers2} from "./config";

const languageCode = localStorage.getItem('locale') || 'uz';

export default class JournalListView extends React.Component {

    constructor() {
        super();
        this.state = {
            journals: [],
            addJournalModal: false,
            journalYear: new Date().getFullYear(),
            journalOrder: 1
        }
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    setValue(e) {
        let name = e.target.id;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    getData() {
        axios.get(apiURL + '/api/journal/list?lang=' + languageCode, headers2).then(result => {
            console.log(result);
            if (result.data.statusCode === 200) {
                let rows = result.data.data.map((row, index) => {
                    return <tr key={getKey()}>
                        <td>{index + 1}</td>
                        <td>{row.year}</td>
                        <td>{row.journalOrder}</td>
                        <td><a className="btn btn-info" href={apiURL + '/files/' + row.year + '/' + row.journalOrder + '.pdf'}
                               target="_blank" rel="noreferrer" title='Ko`rish'><i className='fa fa-eye'/></a>
                            <button className="btn btn-danger ml-1" title='O`chirish' onClick={this.removeJournal.bind(this, row.id)}>
                            <i className="fa fa-trash"/></button></td>
                    </tr>
                });
                this.setState({
                    journals: rows
                })
            }
        })
            .catch((err) => console.error(err));
    }

    removeJournal(id) {
        let data = new FormData();
        data.append('id', id);
        axios.post(apiURL + '/api/journal/remove', data, headers).then(result => {
            if (result.data.statusCode === 200) {
                this.getData();
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    addJournal() {
        let journalYear = document.getElementById('journalYear').value;
        let journalOrder = document.getElementById('journalOrder').value;
        let imageFile = document.getElementById('imageFile').files[0];
        let pdfFile = document.getElementById('pdfFile').files[0];
        let coverPdfFile = document.getElementById('coverPdfFile').files[0];
        let data = new FormData();
        data.append('year', journalYear);
        data.append('order', journalOrder);
        data.append('imageFile', imageFile);
        data.append('pdfFile', pdfFile);
        data.append('coverPdfFile', coverPdfFile);
        axios.post(apiURL + '/api/journal/add', data, headers2).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        });
    }

    openModal() {
        this.setState({
            addJournalModal: true
        })
    }

    closeModal() {
        this.setState({
            addJournalModal: false
        })
    }

    render() {
        return (
            <div className="container-fluid mt-2">
                <div className="form-row">
                    <div className="col-md-2">
                        <button className="btn btn-success" onClick={this.openModal.bind(this)}>Jurnal yuklash</button>
                    </div>
                    <div className="col-md-8 text-center">
                        <h4>Jurnallar ro`yxati</h4>
                    </div>
                    <div className="col-md-2">
                        &nbsp;
                    </div>
                </div>
                <div className="form-row mt-1">
                    <div className="col-md-12">
                        <table className="table table-bordered table-primary">
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>Yil</th>
                                <th>Jurnal soni</th>
                                <th>Amal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.journals}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Modal open={this.state.addJournalModal} onClose={this.closeModal.bind(this)} center>
                    <div className="m-1 form-row">
                        <div className="col-md-12">
                            <div className="modal-body">
                                <div className="addProductForm">
                                    <div className="addProductItem">
                                        <label htmlFor="journalYear">{strings.year2}</label>
                                        <input type="number" id="journalYear" className="form-control"
                                               placeholder="Yilni kiriting" defaultValue={this.state.journalYear} onChange={this.setValue}/>
                                    </div>
                                    <div className="addProductItem">
                                        <label htmlFor="journalOrder">{strings.release}</label>
                                        <select id="journalOrder" className="form-control">
                                            <option value={1}>1-son</option>
                                            <option value={2}>2-son</option>
                                            <option value={3}>3-son</option>
                                            <option value={4}>4-son</option>
                                        </select>
                                    </div>
                                    <div className="addProductItem">
                                        <label htmlFor="imageFile">{strings.imageFile}</label>
                                        <input type="file" id="imageFile"/>
                                    </div>
                                    <div className="addProductItem">
                                        <label htmlFor="coverPdfFile">{strings.coverPdfFile}</label>
                                        <input type="file" id="coverPdfFile"/>
                                    </div>
                                    <div className="addProductItem">
                                        <label htmlFor="pdfFile">{strings.pdfFile}</label>
                                        <input type="file" id="pdfFile"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-success" onClick={this.addJournal.bind(this)}>Saqlash
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}