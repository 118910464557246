import React from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import {apiURL, getKey, strings} from "./Utils";
import {headers} from "./components/config";

class ScientificArticles extends React.Component {

    constructor() {
        super();
        this.state = {
            articles: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        let user = localStorage.getItem('dhruser');
        if (user !== undefined && user !== null) {
            user = JSON.parse(user)
        }
        const languageCode = localStorage.getItem('locale') || 'uz';
        axios.get(apiURL + '/api/journal/article/list?language=' + languageCode).then(result=>{
            if (result.data.statusCode === 200) {
                let articles = result.data.data.map(row=>{
                    return <div className="article pb-5" key={getKey}>
                        <div className="card ">
                            <div className="row g-0">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                                    <img src={apiURL + row.authorImageURL} style={{float: "left", marginRight: "10px"}} height="177px" alt=""/>
                                    <div className="card-body">
                                        <h4 className="card-text"><b>{row.articleName}</b></h4>
                                        <h5 className="card-title">{row.authorName}</h5>
                                        <h6 className="card-text"><small className="text-muted">{strings.doiNumber} {row.doiNumber}</small></h6>
                                        <a target="_blank" rel="noreferrer" className="btn btn-primary" href={row.articleURL}>{strings.read}</a>
                                        {(user !== undefined && user !== null && user.role === 'ADMIN') &&
                                            <button className="btn btn-danger ml-1" title='Maqolani o`chirish' onClick={this.removeArticle.bind(this, row.id)}><i
                                                className='fa fa-trash'/></button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                });
                this.setState({
                    articles: articles
                })
            }
        })
    }

    removeArticle(id) {
        let data = new FormData();
        data.append('id', id);
        axios.post(apiURL + '/admin/article/remove', data, headers).then(result=>{
            if (result.data.statusCode === 200) {
                this.getData();
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    render() {
        return (
            <div className="container articles">
                <h1 className="text-center py-4">{strings.articles}</h1>
                {this.state.articles}
            </div>
        )
    }
}

export default withRouter(ScientificArticles);