import React from 'react';
import '../stayles/Header.css';
import {apiURL, strings} from "../Utils";
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import axios from 'axios';
import swal from 'sweetalert';
import {headers} from "./config";

export default class Header extends React.Component {

    constructor() {
        super();
        this.state = {
            searchResults: [],
            searchResultModal: false
        }
    }

    closeModal() {
        this.setState({
            searchResultModal:false
        })
    }

    search() {
        let searchKeyword = document.getElementById('searchBox').value;
        if (searchKeyword !== null && searchKeyword !=='') {
            axios.get(apiURL + '/journal/search?searchKeyword=' + searchKeyword, headers).then(result=>{
                if (result.data.statusCode === 200) {
                    let results = [];
                    if (result.data.data.length > 0) {
                        results = result.data.data.map(item => {
                            return <div><a href={item.code}>{item.name}</a></div>
                        });
                        this.setState({
                            searchResults: results,
                            searchResultModal: true
                        })
                    } else {
                        swal('Sizning so`rovingiz bo`yicha hech narsa topilmadi', '', 'warning');
                    }
                }
            })
        }
    }

    render() {
        return (
            <div className="container-fluid header">
                <div className="row">
                    <div className="col-md-1 pt-2 pb-2">
                        <a href="/"> <img src="../images/logo.png" alt="" width={"90px"}/></a>
                    </div>
                    <div className="col-md-9 text-center m-auto">
                        <div className="inf">
                            <h2>{strings.journalName1}</h2>
                        </div>
                    </div>

                    <div className="col-md-2 m-auto" style={{padding: "0"}}>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder={strings.search}
                                   aria-describedby="searchButton" style={{border: "1px solid black"}} id="searchBox"/>
                            <button className="btn btn-outline-secondary" id="searchButton"
                                    onClick={this.search.bind(this)}><i className="fa fa-search"/></button>
                        </div>
                    </div>
                    {/*<div className="col-lg-3 inf2" style={{padding: "0 20px"}}>
                    <h5><b>{strings.phrase}</b></h5>
                    <h5 className="text-end font-weight-bold">{strings.shavkatMirziyoyev}</h5>
                </div>*/}
                </div>

                <Modal open={this.state.searchResultModal} onClose={this.closeModal.bind(this)} center>
                    <div className="p-3" style={{minWidth: "300px", minHeight: "400px", maxHeight: "500px", overflowY: "auto"}}>
                        <p><b>Qidiruv natijasi:</b></p>
                        {this.state.searchResults}
                    </div>
                </Modal>
            </div>
        )
    }
}