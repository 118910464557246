import React from 'react';
import {withRouter} from 'react-router-dom';
import {Nav} from "react-bootstrap";
import axios from 'axios';
import {apiURL, getKey, publicResourceURL, strings} from "./Utils";
import BuyJournalView from "./components/BuyJournalView";

const year = 1900 + new Date().getYear();

class ArchiveView extends React.Component {
    constructor() {
        super();
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        let items = [];
        const langCode = localStorage.getItem('locale') || 'uz';
        axios.get(apiURL + '/api/journal/list?lang=' + langCode).then(result => {
            if (result.data.statusCode === 200) {
                for (let i = 0; i < result.data.data.length; i++) {
                    let row = result.data.data[i];
                    let journalOrder = (Number(row.year) === 2000 && row.journalOrder === 1 ? '1-2' :
                        Number(row.year) === 2000 && row.journalOrder === 3 ? '3-4' :
                        Number(row.year) === 2001 && row.journalOrder === 2 ? '2-3' :
                        Number(row.year) === 1999 && row.journalOrder === 3 ? '3-4' :
                        Number(row.year) === 2002 && row.journalOrder === 1 ? '1-2' :
                        Number(row.year) === 2002 && row.journalOrder === 3 ? '3-4' : row.journalOrder);
                    items.push(<div key={getKey()} className="p-4 text-center">
                        <div>
                            <h4>{row.year}</h4>
                            <h4>{strings.years}</h4>
                            <h4>{' №' + journalOrder}</h4>
                        </div>
                        <div className="mainJournal text-center ">
                            <div className="card text-center">
                                <div className="imgBox ">
                                    {Number(row.year) === Number(year) ?
                                        <img src={publicResourceURL + row.mainImageURL} alt=""/> :
                                        <img src={publicResourceURL + row.imageURL} alt=""/>
                                    }
                                </div>
                                {Number(row.year) === Number(year) ?
                                    <div className="details">
                                        <Nav.Link href={publicResourceURL + row.mainPdfURL} rel="noopener" target="_blank"
                                                  className="mundarija">
                                            <button className="btn-primary form-control">{strings.content}</button>
                                        </Nav.Link>
                                        <Nav.Link data-toggle="modal"
                                                  data-target="#myModal2">{strings.buy}</Nav.Link>
                                    </div> : <div className="details">
                                        <Nav.Link href={publicResourceURL + row.pdfURL} rel="noopener" target="_blank"
                                                  className="mundarija">
                                            <button
                                                className="btn btn-primary form-control ">{strings.read}</button>
                                        </Nav.Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>);
                }
                this.setState({
                    data: items
                });
            }
        });
    }

    render() {
        return (
            <div className="container-fluid archive">
                <div className="form-row py-4">
                    <div className="col-md-12 text-center pt-4">
                        <h2>{strings.allJournals}</h2>
                    </div>
                </div>
                <div className="form-row">
                    {this.state.data}
                </div>

                <BuyJournalView/>
            </div>
        )
    }
}

export default withRouter(ArchiveView);
