import {strings} from "../Utils";

export default function Footer() {
    return (
        <div className="container-fluid footer">
            <div className="row">
                <div className="col-md-5 d-flex">
                    <h6 className="m-4">{strings.journalName1} <br/>{strings.journalName2}</h6>
                </div>
                <div className="col-md-3 mt-4">
                    <p><i className="fa fa-mail-bulk px-2"/> journal@nhrc.uz</p>
                    <p><i className="fas fa-phone-square-alt px-2"/>+998 71 239 41 42</p>

                </div>
                <div className="col-md-4 mt-4 ">
                    <p><i className="fas fa-map-marker-alt px-2"/>{strings.streetName}</p>
                    <h6><i className="fa fa-keyboard px-2"/>{strings.makeSayt}<a href="http://softuz.uz" className="text-decoration-none"> UzSoft</a></h6>
                </div>
            </div>
        </div>
    )
}