import "./newProduct.css";
import {apiURL} from "../../Utils";
import axios from 'axios';
import swal from 'sweetalert';
import {withRouter} from "react-router-dom";
import {headers} from "../../components/config";

const addNewJournal = () => {
    let journalYear = document.getElementById('journalYear').value;
    let journalOrder = document.getElementById('journalOrder').value;
    let imageFile = document.getElementById('imageFile').files[0];
    let pdfFile = document.getElementById('pdfFile').files[0];
    let data = new FormData();
    data.append('year', journalYear);
    data.append('order', journalOrder);
    data.append('imageFile', imageFile);
    data.append('pdfFile', pdfFile);
    axios.post(apiURL + '/journal/add', data, headers).then(result=>{
        if (result.data.statusCode === 200) {
            swal(result.data.message, '', 'success');
        } else {
            swal(result.data.message, '', 'error');
        }
    })
};

function AddJournalView() {
    return (
        <div className="newProduct">
            <h1 className="addProductTitle">Jurnalning yangi soni</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label htmlFor="journalYear">Yil</label>
                    <input type="number" id="journalYear" className="form-control" placeholder="Yilni kiriting"/>
                </div>
                <div className="addProductItem">
                    <label htmlFor="journalOrder">Son</label>
                    <select id="journalOrder" className="form-control">
                        <option value={1}>1-son</option>
                        <option value={2}>2-son</option>
                        <option value={3}>3-son</option>
                        <option value={4}>4-son</option>
                    </select>
                </div>
                <div className="addProductItem">
                    <label htmlFor="imageFile">Rasm fayli</label>
                    <input type="file" id="imageFile"/>
                </div>
                <div className="addProductItem">
                    <label htmlFor="pdfFile">PDF fayl</label>
                    <input type="file" id="pdfFile"/>
                </div>
                <button className="addProductButton" onClick={addNewJournal}>Saqlash</button>
            </div>
        </div>
    );
}

export default withRouter(AddJournalView);
