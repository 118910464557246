import React from "react";
import axios from "axios";
import {apiURL, getUser, strings} from "../Utils";
import swal from "sweetalert";
import {withRouter} from "react-router-dom";
import {headers} from "./config";

function EditUserView() {

    const editUser = () => {
        let lastName = document.getElementById('lastName4').value;
        let firstName = document.getElementById('firstName4').value;
        let phoneNumber = document.getElementById('phoneNumber4').value;
        let emailAddress = document.getElementById('emailAddress4').value;
        let address = document.getElementById('address4').value;
        let data = new FormData();
        let user = getUser();
        data.append('userId', user.id);
        data.append('lastName', lastName);
        data.append('firstName', firstName);
        data.append('email', emailAddress);
        data.append('address', address);
        data.append('phoneNumber', phoneNumber);
        axios.post(apiURL + '/api/user/edit', data, headers).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    };

    return (
        <div>
            <div className="modal fade" id="myModal4">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4>{strings.edit}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="container-fluid">
                            <div className="form-group w-100">
                                <label htmlFor="lastName4">{strings.lastName}</label>
                                <input type="text" className="form-control" placeholder={strings.enterLastName} id="lastName4"/>
                            </div>
                            <div className="form-group w-100">
                                <label htmlFor="firstName4">{strings.firstName}</label>
                                <input type="text" className="form-control" placeholder={strings.enterFirstName} id="firstName4"/>
                            </div>

                            <div className="form-group w-100">
                                <label htmlFor="address4">{strings.address}</label>
                                <input type="text" className="form-control" placeholder={strings.enterAddress} id="address4"/>
                            </div>
                            <div className="form-group w-100">
                                <label htmlFor="emailAddress4">{strings.email}</label>
                                <input type="text" className="form-control" placeholder={strings.enterEmail} id="emailAddress4"/>
                            </div>
                            <div className="form-group w-100">
                                <label htmlFor="phoneNumber4">{strings.phoneNumber}</label>
                                <input type="number" className="form-control" placeholder={strings.enterPhoneNumber} id="phoneNumber4"/>
                            </div>

                        </div>

                        <div className="modal-footer justify-content-center d-flex">
                            <button type="button" className="btn btn-success"
                                    onClick={editUser}>{strings.save}</button>

                            <button type="button" className="btn btn-danger"
                                    data-dismiss="modal">{strings.cancel}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(EditUserView);