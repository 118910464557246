import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

function InformationAll() {
    return (
        <div className="container bg-light px-5">
            <div className="row">
                <div className="col-lg-12 mb-3 text-justify">
                    <h3 className="text-center py-5">{strings.aboutJournal1}</h3>
                    <h5>&ensp; &ensp; {strings.aboutJournal2}</h5>
                    <h5>&ensp; &ensp; {strings.aboutJournal3}</h5>
                    <h5>&ensp; &ensp; {strings.aboutJournal4}</h5>
                    <ul>
                        <li><h5 className="font-weight-bold">{strings.aboutJournal5}</h5></li>
                        <li><h5 className="font-weight-bold">{strings.aboutJournal6}</h5></li>
                        <li><h5 className="font-weight-bold">{strings.aboutJournal7}</h5></li>
                        <li><h5 className="font-weight-bold">{strings.aboutJournal8}</h5></li>
                        <li><h5 className="font-weight-bold">{strings.aboutJournal9}</h5></li>
                        <li><h5 className="font-weight-bold">{strings.aboutJournal10}</h5></li>
                    </ul>
                    <h5>&ensp; &ensp; {strings.aboutJournal11}</h5>
                </div>
            </div>
        </div>
    )

}

export default withRouter(InformationAll);