import "./stayles/InterMembers.css"
import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

function InternationalMembers() {
    return (
        <div className="container member1 p-1">
            <div className="text-center py-4"><h1>{strings.interMembers}</h1></div>
            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/Tanzilla.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.tanzilaNarbayeva}</h3>
                            <p className="card-text">{strings.tanzilaNarbayevaDesc}</p>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/rafiqov.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.qudratillaRafiqov}</h3>
                            <p className="card-text">{strings.qudratillaRafiqovDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/aslanAbashidze.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.aslan}</h3>
                            {/*<h5 className="card-title ">{strings.aslanContry}</h5>*/}
                            <p className="card-text">{strings.aslanDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/akira.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.akira}</h3>
                            {/*<h5 className="card-title ">{strings.akiraContry}</h5>*/}
                            <p className="card-text">{strings.akiraDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/batler.png" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.uilyam}</h3>
                            {/*<h5 className="card-title ">{strings.uilyamCountry}</h5>*/}
                            <p className="card-text">{strings.uilyamDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/Tatyana.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.tatyana}</h3>
                            {/*<h5 className="card-title ">{strings.tatyanaCountry}</h5>*/}
                            <p className="card-text">{strings.tatyanaDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/Salim.png" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.margub}</h3>
                            {/*<h5 className="card-title ">{strings.margubCountry}</h5>*/}
                            <p className="card-text">{strings.margubDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/reyn.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.reyn}</h3>
                            {/*<h5 className="card-title ">{strings.reynCountry}</h5>*/}
                            <p className="card-text">{strings.reynDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/tadueshBodio.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.bodio}</h3>
                            {/*<h5 className="card-title ">{strings.bodioCountry}</h5>*/}
                            <p className="card-text">{strings.bodioDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/sergey.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.sergey}</h3>
                            {/*<h5 className="card-title ">{strings.sergeyCountry}</h5>*/}
                            <p className="card-text">{strings.sergeyDesc}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default withRouter(InternationalMembers);