import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import '../stayles/Menu.css';
import {BrowserRouter as Router, Link, Route, Switch, withRouter} from "react-router-dom";
import InformationAll from "../InformationAll";
import HomePage from "../HomePage";
import Litsenziya from "../Litsenziya";
import AboutUs from "../AboutUs";
import InternationalMembers from "../InternationalMembers";
import LoginPage from "./LoginPage";
import NationalMembers from "../NationalMembers";
import JournalRequest from "../JournalRequest";
import PublishProcedure from "../PublishProcedure";
import ArchiveView from "../ArchiveView";
import LocalizationButton from "./LocalizationButton";
import AdminHomeView from "../AdminHomeView";
import AddJournalView from "../pages/addJournal/AddJournalView";
import {apiURL, getUser, isAdmin, logout, strings} from "../Utils";
import UserList from "../pages/userList/UserList";
import axios from 'axios';
import swal from 'sweetalert';
import UserHomePage from "../UserHomePage";
import SubscriptionView from "./SubscriptionView";
import React from "react";
import EditUserView from "./EditUserView";
import BuyJournalView from "./BuyJournalView";
import ScientificArticles from "../ScientificArticles";
import OrganizationStructure from "../OrganizationStructure";
import User from "../pages/user/User";
import NewUser from "../pages/newUser/NewUser";
import PaymentListView from "./PaymentListView";
import ArticleListView from "./article/ArticleListView";
import JournalListView from "./JournalListView";
import FileListView from "./FileListView";
import Input from 'react-phone-number-input/input';
import {headers} from "./config";
import EditArticleView from "./article/EditArticleView";

class Menu extends React.Component {
    constructor(props) {
        super(props);
        let user = getUser();
        this.state = {
            user: user,
            f_i_sh: '',
            email: '',
            phoneNumber: '',
            articleName: '',
            isOpen: false
        }
        this.setPhone = this.setPhone.bind(this);
        this.setValue = this.setValue.bind(this);
        this.openCloseMenu = this.openCloseMenu.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    onChangeLanguage(lang) {
        this.props.changeLanguage(lang);
        this.toggle();
    }

    setPhone(e) {
        this.setState({
            phoneNumber: e
        })
    }

    setValue(e) {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    openCloseMenu() {
        let navbar = document.getElementById('basic-navbar-nav');
        if (!navbar.classList.contains("show")) {
            navbar.classList.add("show");
        } else {
            navbar.classList.remove("show");
        }
    }

    savePublicSubscription() {
        let lastName = document.getElementById('lastName1').value;
        let firstName = document.getElementById('firstName1').value;
        let address = document.getElementById('address1').value;
        let phoneNumber = document.getElementById('phoneNumber1').value;
        let data = new FormData();
        data.append('lastName', lastName);
        data.append('firstName', firstName);
        data.append('address', address);
        data.append('phoneNumber', phoneNumber);
        axios.post(apiURL + '/api/subscription/public/add', data, headers).then(result => {
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    onSend() {
        let data = new FormData();
        let fish = this.state.f_i_sh;
        if (fish === null || fish === '') {
            swal('Familiya, ism, sharifingizni kiriting', '', 'error');
            return;
        }
        data.append('authorName', fish);

        let email = this.state.email;
        if (email === null || email === '') {
            swal('Emailingizni kiriting', '', 'error');
            return;
        } else if (!email.includes('@')) {
            swal(strings.incorrectEmail, '', 'error');
            return;
        }

        let phoneNumber = this.state.phoneNumber;
        if (phoneNumber === null || phoneNumber === '') {
            swal(strings.enterPhoneNumber, '', 'error');
            return;
        } else if (phoneNumber.length < 13 || phoneNumber.length > 13) {
            swal(strings.incorrectPhoneNumber, '', 'error');
            return;
        }

        let articleFile = document.getElementById('articleFile').files;
        if (articleFile === null || articleFile.length === 0) {
            swal('Maqola faylini yuklang', '', 'error');
            return;
        }

        let articleName = this.state.articleName;
        if (articleName === null || articleName === '') {
            swal('Maqola nomini kiriting', '', 'error');
            return;
        }

        data.append('authorName', fish);
        data.append('email', email);
        data.append('phoneNumber', phoneNumber);
        data.append('articleFile', articleFile[0]);
        data.append('articleName', articleName);
        axios.post(apiURL + '/journal/article/sendToCenter', data, headers).then(result => {
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    render() {
        return (
            <Router>
                <div className="menuNavbar">
                    <Navbar bg="lightblue" expand="xl" variant="dark" fixed="" sticky="top" expanded={this.state.isOpen}>
                        <div className="w-100">
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.toggle}/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto" navbar>
                                    <Nav.Link href="/home">{strings.home}</Nav.Link>
                                    <NavDropdown title={strings.aboutJournal} id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} onClick={this.toggle}
                                                          to={"infor"}>{strings.allInformation1}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to={"aboutus"}>{strings.aboutus}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle}
                                                          to={"orgStructure"}>{strings.orgStructure}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle}
                                                          to={"litsenziya"}>{strings.litsenziya}</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title={strings.members} id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} onClick={this.toggle}
                                                          to={"interMembers"}>{strings.interMembers}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle}
                                                          to={"nationalMembers"}>{strings.nationalMembers}</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title={strings.toAuthor} id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to={"request"}>{strings.request}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to={"publish"}>{strings.publish}</NavDropdown.Item>
                                        <NavDropdown.Item data-toggle="modal" onClick={this.toggle}
                                                          data-target="#sendArticle">{strings.sendArticle}</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link as={Link} to="/scientificArticles" onClick={this.openCloseMenu}>{strings.articles}</Nav.Link>
                                    <Nav.Link as={Link} to="/archive" onClick={this.openCloseMenu}>{strings.electronLibrary}</Nav.Link>
                                    <NavDropdown title={strings.agreement} id="shartnoma">
                                        <NavDropdown.Item as={Link} target="_blank" onClick={this.toggle}
                                                          to="./files/Shartnoma.pdf">{strings.readAgreement}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} target="_blank" onClick={this.toggle}
                                                          to="./files/Shartnoma.docx">{strings.downloadAgreement}</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link data-toggle="modal" data-target="#myModal2" onClick={this.toggle}>{strings.buy}</Nav.Link>
                                    <LocalizationButton changeLanguage={this.onChangeLanguage}/>
                                    {isAdmin(this.state.user) &&
                                    <NavDropdown title={this.state.user.lastName + ' ' + this.state.user.firstName} id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to="/users">{strings.subscribers}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to="/payments">{strings.payments}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to="/articles">{strings.articleList}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to="/journals">{strings.journalList}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} onClick={this.toggle} to="/fileList">{strings.fileList}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/logout" onClick={logout.bind(this)}>{strings.logout}</NavDropdown.Item>
                                    </NavDropdown>
                                    }
                                </Nav>
                                {(this.state.user === undefined || this.state.user === null) &&
                                <>
                                    <LoginPage/>
                                    {/*<SingPage/>*/}
                                </>
                                }
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </div>

                <div>
                    <div className="modal fade" id="myModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{strings.publishSubscription}</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group w-100">
                                        <label htmlFor="lastName1">{strings.lastName}</label>
                                        <input className="form-control" placeholder={strings.enterLastName}
                                               id="lastName1"/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label htmlFor="firstName1">{strings.firstName}</label>
                                        <input className="form-control" placeholder={strings.enterFirstName}
                                               id="firstName1"/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label htmlFor="address1">{strings.address}</label>
                                        <input className="form-control" placeholder={strings.enterAddress}
                                               id="address1"/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label htmlFor="phoneNumber1">{strings.phoneNumber}</label>
                                        <Input international withCountryCallingCode defaultCountry="UZ"
                                               country="UZ" onChange={this.setPhone} maxLength={17}
                                               className="form-control" id="phoneNumber1"/>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="button" className="btn btn-success"
                                            onClick={this.savePublicSubscription.bind(this)}>{strings.save}</button>
                                    <button type="button" className="btn btn-danger"
                                            data-dismiss="modal">{strings.cancel}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SubscriptionView/>
                <EditUserView/>
                <div>
                    <div className="modal fade" id="sendArticle">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{strings.sendArticle}</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                    <div className="form-group w-100">
                                        <label htmlFor="f_i_sh">{strings.f_i_sh}</label>
                                        <input className="form-control" placeholder={strings.enterName} id="f_i_sh" onChange={this.setValue}/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label htmlFor="email">{strings.email}</label>
                                        <input type="email" className="form-control" placeholder={strings.enterEmail}
                                               id="email" onChange={this.setValue}/>
                                    </div>
                                    <div className="form-group w-100">
                                        <label htmlFor="phoneNumber">{strings.phoneNumber}</label>
                                        <Input international withCountryCallingCode defaultCountry="UZ"
                                               country="UZ" onChange={this.setPhone} maxLength={17}
                                               className="form-control" id="phoneNumber"/>
                                    </div>
                                    <div className="custom-file">
                                        <div className="mb-3">
                                            <label htmlFor="articleFile">{strings.markFile}</label>
                                            <input id="articleFile" type="file" className="form-control"/>
                                        </div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="articleName">{strings.article}</label>
                                        <textarea className="form-control" rows="5" id="articleName" onChange={this.setValue}/>
                                    </div>
                                </div>

                                <div className="modal-footer justify-content-center">
                                    <button type="button" className="btn btn-success"
                                            onClick={this.onSend.bind(this)}>{strings.send}</button>
                                    <button type="button" className="btn btn-danger"
                                            data-dismiss="modal">{strings.cancel}</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <BuyJournalView/>
                    <SubscriptionView/>
                </div>
                <div>
                    <Switch>
                        <Route exact path='/' component={HomePage}/>
                        <Route path='/home' component={HomePage}/>
                        <Route path='/infor' component={InformationAll}/>
                        <Route path='/litsenziya' component={Litsenziya}/>
                        <Route path='/aboutus' component={AboutUs}/>
                        <Route path='/interMembers' component={InternationalMembers}/>
                        <Route path='/nationalMembers' component={NationalMembers}/>
                        <Route path='/request' component={JournalRequest}/>
                        <Route path='/publish' component={PublishProcedure}/>
                        <Route path='/scientificArticles' render={(props) => (
                            <ScientificArticles {...props}/>
                        )}
                        />
                        <Route path='/archive' component={ArchiveView}/>
                        <Route path='/admin' component={AdminHomeView}/>
                        <Route path='/product' component={AddJournalView}/>
                        <Route path='/users' component={UserList}/>
                        <Route path='/user' component={UserHomePage}/>
                        <Route path='/orgStructure' component={OrganizationStructure}/>
                        <Route path='/payments' component={PaymentListView}/>
                        <Route path="/user/:userId" component={User}/>
                        <Route path="/newUser" component={NewUser}/>
                        <Route path="/newJournal" component={AddJournalView}/>
                        <Route path="/articles" component={ArticleListView}/>
                        <Route path="/journals" component={JournalListView}/>
                        <Route path="/fileList" component={FileListView}/>
                        <Route path="/editArticle" component={EditArticleView}/>
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default withRouter(Menu);