import "./userList.css";
import React from "react";
import {apiURL} from "../../Utils";
import axios from 'axios';
import {withRouter} from "react-router-dom";
import swal from "sweetalert";
import {headers} from "../../components/config";

class UserList extends React.Component {

    constructor() {
        super();
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        axios.get(apiURL + '/api/user/list', headers).then(result => {
            if (result.data.statusCode === 200) {
                let data = result.data.data.map(row => {
                    return <tr key={'u_' + row.id}>
                        <td>{row.id}</td>
                        <td>{row.lastName}</td>
                        <td>{row.firstName}</td>
                        <td>{row.email}</td>
                        <td>{row.address}</td>
                        <td><button className="btn btn-danger" onClick={this.removeUser.bind(this, row.id)}><i className="fa fa-trash"/></button></td>
                    </tr>
                });
                this.setState({
                    data: data
                });
            }
        });
    }

    removeUser(id) {
        let data = new FormData();
        data.append('id', id);
        axios.post(apiURL + '/user/remove', data, headers).then(result => {
            if (result.data.statusCode === 200) {
                this.getData();
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    render() {
        return (
            <div>
                <div className="form-row mt-3">
                    <div className="col-md-12 text-center">
                        <h4><b>Obunachilar ro`yxati</b></h4>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="userList">
                            <table className="table table-bordered table-primary">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Familiyasi</th>
                                    <th>Ismi</th>
                                    <th>Email</th>
                                    <th>Manzili</th>
                                    <th>Amal</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UserList);
