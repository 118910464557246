import React from "react";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {apiURL, getKey, publicResourceURL, strings} from "../Utils";

class ControlledCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            buttons: [],
            images: [],
        }
    }

    componentDidMount() {
        axios.get(apiURL + "/api/journal/slideImages/list")
            .then(result=>{
                if (result.status === 200) {
                    let buttons = [], images = [];
                    for (let i=0; i<result.data.length; i++) {
                        buttons.push(
                            <button type="button" key={getKey()} data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i}
                                    className={i === 0 ? "active" : ""}
                                    aria-current="true" aria-label={"Slide " + i}/>
                        );
                        images.push(
                            <div className={i === 0 ? "carousel-item active" : "carousel-item"} key={getKey()}>
                                <img key={getKey()} src={publicResourceURL + "/images/slideImages/" + result.data[i] + ".jpg"} className="d-block w-100" alt="..."/>
                            </div>
                        );
                    }
                    this.setState({
                        buttons: buttons,
                        images: images
                    })
                }
            })
    }

    render() {
        return (
            <div id="carouselExampleCaptions" className="carousel slide carousel-fade mt-1" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {this.state.buttons}
                </div>
                <div className="carousel-inner">
                    {this.state.images}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                    <span className="visually-hidden">{strings.previous}</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                    <span className="visually-hidden">{strings.next}</span>
                </button>
            </div>
        )
    }
}

export default withRouter(ControlledCarousel);