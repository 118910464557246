import {withRouter} from "react-router-dom";

function OrganizationStructure() {
    const currentLanguageCode = localStorage.getItem('locale') || 'uz';
    return (
        <div className="container">
            <img src={'./images/structure_' + currentLanguageCode + '.jpg'} alt="" className="w-100"/>
        </div>
    );
}
export default withRouter(OrganizationStructure);