import React, {Component} from "react";
import axios from "axios";
import {apiURL} from "../../Utils";
import {headers, headers2} from "../config";
import swal from "sweetalert";

export default class EditArticleView extends Component {

    constructor() {
        super();
        let params = new URLSearchParams(window.location.search);
        console.log("ID=" + params.get("id"));
        this.state = {
            article: {
                id: params.get("id"),
                articleName: '',
                year: null,
                journalOrder: null,
                doiNumber: '',
                authorName: '',
                language: 'uz'
            }
        }
        this.setValue = this.setValue.bind(this);
    }

    componentDidMount() {
        axios.get(apiURL + '/api/journal/article/get?id=' + this.state.article.id, headers2).then(result => {
            if (result.status === 200) {
                this.setState({
                    article: result.data.data
                })
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    setValue(e) {
        let name = e.target.id;
        let value = e.target.value;
        this.setState({
            article: {
                ...this.state.article,
                [name]: value
            }
        })
    }

    save() {
        let data = new FormData();
        data.append("id", this.state.article.id);
        data.append("year", this.state.article.year);
        data.append("journalOrder", this.state.article.journalOrder);
        data.append("articleName", this.state.article.articleName);
        data.append("doiNumber", this.state.article.doiNumber);
        data.append("language", this.state.article.language);
        data.append("authorName", this.state.article.authorName);
        data.append("authorImageFile", document.getElementById('authorImageFile').files[0]);
        data.append("articlePDFFile", document.getElementById('articlePDFFile').files[0]);
        axios.post(apiURL + '/api/journal/article/edit', data, headers)
            .then(result => {
                if (result.status === 200) {
                    swal(result.data.message, "", "success");
                } else {
                    swal(result.data.message, "", "error");
                }
            })
    }

    render() {
        return <div className="container-fluid">
            <div className="m-1 form-row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-12">
                            <label htmlFor="articleName">Maqola nomi</label>
                            <textarea id="articleName" className="form-control" defaultValue={this.state.article.articleName}
                                   onChange={this.setValue}/>
                        </div>
                    </div>
                    <div className="form-row mt-3 d-flex">
                        <div className="col-md-2">
                            <label htmlFor="journalYear">Yili</label>
                            <input id="journalYear" type="number" className="form-control"
                                   defaultValue={this.state.article.year} onChange={this.setValue}/>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="journalOrder">Soni</label>
                            <input id="journalOrder" type="number" className="form-control"
                                   defaultValue={this.state.article.journalOrder} onChange={this.setValue}/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="articleLanguage">Maqola tili</label>
                            <select id="articleLanguage" className="form-control"
                                    defaultValue={this.state.article.language} onChange={this.setValue}>
                                <option value='uz'>O`zbek tili</option>
                                <option value='kr'>Ўзбек тили</option>
                                <option value='ru'>Rus tili</option>
                                <option value='en'>Ingliz tili</option>
                            </select>
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="doiNumber">DOI raqami</label>
                            <input id="doiNumber" className="form-control" defaultValue={this.state.article.doiNumber}
                                   onChange={this.setValue}/>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-6">
                            <label htmlFor="authorName">Muallif nomi</label>
                            <input id="authorName" className="form-control" defaultValue={this.state.article.authorName}
                                   onChange={this.setValue}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="authorImageFile">Muallif rasmi</label>
                            <input id="authorImageFile" type="file" accept='image/jpeg, image/png'
                                   className="form-control"/>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12">
                            <label htmlFor="articlePDFFile">PDF fayli</label>
                            <input id="articlePDFFile" type="file" accept='application/pdf' className="form-control"/>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-success" onClick={this.save.bind(this)}>Saqlash</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}