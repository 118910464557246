import React from "react";
import {withRouter} from "react-router-dom";
import {apiURL, getKey} from "../Utils";
import axios from "axios";
import swal from "sweetalert";
import {headers} from "./config";

class PaymentListView extends React.Component {

    state = {
        payments: []
    }

    componentDidMount() {
        this.getPaymentList();
    }

    getPaymentList() {
        axios.get(apiURL + '/api/subscription/payment/list', headers).then(result => {
            if (result.data.statusCode === 200) {
                let rows = result.data.data.map((row, index) => {
                    return <tr key={getKey()}>
                        <td>{index + 1}</td>
                        <td>{row.payedBy}</td>
                        <td>{row.journals}</td>
                        <td>{row.amount}</td>
                        <td>{row.status}</td>
                        <td>{row.paymentDate}</td>
                        <td><button className="btn btn-danger" onClick={this.removeItem.bind(this, row.id)}><i className="fa fa-trash"/></button></td>
                    </tr>
                });
                this.setState({
                    payments: rows
                })
            }
        })
    }

    removeItem(id) {
        let data = new FormData();
        data.append('id', id);
        axios.post(apiURL + '/admin/payment/remove', data, headers).then(result=>{
            if (result.data.statusCode === 200) {
                this.getPaymentList();
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    render() {
        return (
            <div>
                <div className="form-row mt-3">
                    <div className="col-md-12 text-center">
                        <h4><b>To`lovlar ro`yxati</b></h4>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="container-fluid ">
                            <table className="table table-bordered table-primary">
                                <thead>
                                <tr>
                                    <th>№</th>
                                    <th>To`lovchi</th>
                                    <th>Tanlangan sonlar</th>
                                    <th>Summasi</th>
                                    <th>Holati</th>
                                    <th>Vaqti</th>
                                    <th>Amal</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.payments}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PaymentListView);