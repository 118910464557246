import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {strings} from "../Utils";

export default function JournalsSlide() {
    return(
        <div className="container-fluid px-0  mainSlayde text-center">
            <h3 className="text-center pt-5">{strings.electronLibrary}</h3>
            <iframe src="slider.html" frameBorder="0" title="Slide" width="100%" height="600px"/>

            <Button as={Link} to={"archive"}  className="btn btn-primary" width={"20px"}>{strings.electronLibrary}</Button>
        </div>
    )
    
}
