import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

function JournalRequest() {
    const langCode = localStorage.getItem('locale') || 'uz';
    return (
        <div className="container bg-light px-5">
            <div className="title text-center py-4">
                <h2>{strings.journalRequests}</h2>
            </div>
            <div className="informations">
                <div className="information py-1 text-justify">
                    <ul>
                        <li><h5>{strings.journalRequests1}</h5></li>
                        <li><h5>{strings.journalRequests2}</h5></li>
                        <li><h5>{strings.journalRequests3}</h5></li>
                        <li><h5>{strings.journalRequests4}</h5></li>
                        <li><h5>{strings.journalRequests5}</h5></li>
                        <li><h5>{strings.journalRequests6}</h5></li>
                        <li><h5>{strings.journalRequests7}</h5></li>
                        <li><h5>{strings.journalRequests8}</h5></li>
                        <li><h5>{strings.journalRequests9}</h5></li>
                        <li><h5>{strings.journalRequests10}</h5></li>
                        <li><h5>{strings.journalRequests11}</h5></li>
                        <li><h5>{strings.journalRequests12}</h5></li>
                        {/*<li><h5>{strings.journalRequests13}</h5></li>*/}
                        <li><h5>{strings.journalRequests14}</h5></li>
                    </ul>
                </div>
            </div>
            <div className="text-center py-4">
                <a target="_blank" rel="noreferrer" className="btn btn-primary px-5 " href={"./files/requirements_" + langCode + ".pdf"}>{strings.downloadSamples}</a>
            </div>

        </div>
    )

}

export default withRouter(JournalRequest);