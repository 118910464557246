import "./stayles/InterMembers.css";
import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

function NationalMembers() {
    return (
        <div className="container member1 p-1">
            <div className=" py-4 text-center"><h1>{strings.nationalMembers}</h1></div>
            <div className="row text-center ">

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/Tillaboyev.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.mirzatilloTillabayev}</h3>
                            <p className="card-text">{strings.mirzatilloTillabayevDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/halimBoboyev.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body">
                            <h3 className="card-title">{strings.halimBoboyev}</h3>
                            <p className="card-text">{strings.halimBoboyevDesc}</p>
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2 ">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/oqyolov.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body">
                            <h3 className="card-title">{strings.omonOqyulov}</h3>
                            <p className="card-text">{strings.omonOqyulovDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/asqarGafurov.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body">
                            <h3 className="card-title">{strings.asqarGafurov}</h3>
                            <p className="card-text">{strings.asqarGafurovDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/mamasiddiqov.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body ">
                            <h3 className="card-title ">{strings.muzaffarMamasiddiqov}</h3>
                            <p className="card-text">{strings.muzaffarMamasiddiqovDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/mihail.jpg" className="card-img-top" alt="#"/>
                        <div className="card-body">
                            <h3 className="card-title">{strings.mixailGasanov}</h3>
                            <p className="card-text">{strings.mixailGasanovDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/kutibayeva.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body">
                            <h3 className="card-title">{strings.elizavetaKutibayeva}</h3>
                            <p className="card-text">{strings.elizavetaKutibayevaDesc}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2">
                    <div className="card shadow  bg-white rounded">
                        <img src="./images/haydarali.jpg" className="card-img-top " alt="#"/>
                        <div className="card-body">
                            <h3 className="card-title">{strings.haydaraliMuxamedov}</h3>
                            <p className="card-text">{strings.haydaraliMuxamedovDesc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(NationalMembers);