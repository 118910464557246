import React from "react";
import {withRouter} from "react-router-dom";
import {apiURL, strings} from "../Utils";
import axios from "axios";
import swal from 'sweetalert';
import Input from 'react-phone-number-input/input';
import {headers} from "./config";

class Contact extends React.Component {

    constructor() {
        super();
        this.state = {
            fullName: '',
            c_email: '',
            c_phoneNumber: '',
            comment: '',
        };
        this.setPhone = this.setPhone.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    setPhone(e) {
        this.setState({
            c_phoneNumber: e
        })
    }

    setValue(e) {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    submitFeedback() {
        let fullName = this.state.fullName;
        if (fullName === null || fullName === '') {
            swal('Yuboruvchi F.I.Shni kiriting!', '', 'error');
            return;
        }
        let email = this.state.c_email;
        if (email === null || email === '') {
            swal('Emailingizni kiriting', '', 'error');
            return;
        } else if (!email.includes('@')) {
            swal(strings.incorrectEmail, '', 'error');
            return;
        }
        let phoneNumber = this.state.c_phoneNumber;
        if (phoneNumber === null || phoneNumber === '') {
            swal(strings.enterPhoneNumber, '', 'error');
            return;
        } else if (phoneNumber.length < 13 || phoneNumber.length > 13) {
            swal(strings.incorrectPhoneNumber, '', 'error');
            return;
        }
        let comment = this.state.comment;
        if (comment === null || comment === '') {
            swal(strings.enterComment, '', 'error');
            return;
        }
        let data = new FormData();
        data.append('sender', fullName);
        data.append('email', email);
        data.append('phoneNumber', phoneNumber);
        data.append('comment', comment);
        axios.post(apiURL + '/admin/feedback/add', data, headers)
            .then(result => {
            if (result.data.statusCode === 200) {
                swal(strings.yourFeedbackSent, '', 'success');
            } else {
                swal(strings.errorWhenSentYourFeedback, '', 'error');
            }
        })
    }

    render() {
        return (
            <div className="contact mx-5">
                <div className="form-group w-75">
                    <label htmlFor="fullName">{strings.f_i_sh}</label>
                    <input className="form-control" placeholder={strings.enterName} id="fullName" onChange={this.setValue}/>
                </div>
                <div className="form-group w-75">
                    <label htmlFor="c_email">{strings.email}</label>
                    <input type="email" className="form-control" placeholder={strings.enterEmail} id="c_email" onChange={this.setValue}/>
                </div>
                <div className="form-group w-75">
                    <label htmlFor="c_phoneNumber">{strings.phoneNumber}</label>
                    <Input international withCountryCallingCode defaultCountry="UZ"
                           country="UZ" onChange={this.setPhone} maxLength={17}
                           className="form-control" id="c_phoneNumber"/>
                </div>

                <div className="form-group">
                    <label htmlFor="comment">{strings.forReference}</label>
                    <textarea className="form-control" rows="8" id="comment" onChange={this.setValue}/>
                </div>
                <button className="btn btn-primary" onClick={this.submitFeedback.bind(this)}>{strings.send}</button>
            </div>
        )
    }
}

export default withRouter(Contact);