import React from 'react';
import ArchiveView from "./ArchiveView";
import {withRouter} from "react-router-dom";

class UserHomePage extends React.Component {

    render() {
        return(
            <div>
                <ArchiveView/>
            </div>
        )
    }
}

export default withRouter(UserHomePage);