import React, {useState} from "react";
import axios from "axios";
import {apiURL, strings} from "../Utils";
import swal from "sweetalert";
import {withRouter} from "react-router-dom";

function SubscriptionView() {
    const[subscriptionType, setSubscriptionType] = useState('electronSubs');
    let[subscriberId, setSubscriberId] = useState('');
    const[phoneNumber, setPhoneNumber] = useState('');


    const saveElectronSubscription = () => {
        let lastName = document.getElementById('lastName2').value;
        let firstName = document.getElementById('firstName2').value;
        let phoneNumber = document.getElementById('phoneNumber2').value;
        let data = new FormData();
        data.append('lastName', lastName);
        data.append('firstName', firstName);
        if (subscriptionType.includes('electronSubs')) {
            let emailAddress = document.getElementById('emailAddress2').value;
            data.append('email', emailAddress);
        } else {
            let address = document.getElementById('address2').value;
            data.append('address', address);
        }
        data.append('phoneNumber', phoneNumber);
        let url = subscriptionType.includes('electronSubs') ? '/electron/add' : '/public/add'
        axios.post(apiURL + '/subscription' + url, data).then(result=>{
            if (result.data.statusCode === 200) {
                setSubscriberId('' + result.data.data);
                swal(result.data.message, '', 'success');
                console.log(subscriberId);
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    };

    const changeSubsType = (e) => {
        setSubscriptionType(e.target.id);
    }

    return (
        <div>
            <div className="modal fade" id="myModal3">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4>{strings.subscription}</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group w-100 d-flex">
                                <div className="form-check m-2">
                                    <input className="form-check-input" type="radio" name="subscriptionType" id="electronSubs"
                                           value="option1" defaultChecked = {subscriptionType.includes('electronSubs')} onChange={changeSubsType}/>
                                    <label className="form-check-label" htmlFor="electronSubs">Elektron shaklga obuna</label>
                                </div>
                                <div className="form-check m-2">
                                    <input className="form-check-input" type="radio" name="subscriptionType" id="printedSubs"
                                           value="option2" defaultChecked={subscriptionType.includes('printedSubs')} onChange={changeSubsType}/>
                                    <label className="form-check-label" htmlFor="printedSubs">Bosma shaklga obuna</label>
                                </div>
                            </div>
                            <div className="form-group w-100">
                                <label htmlFor="lastName2">{strings.lastName}</label>
                                <input type="text" className="form-control" placeholder={strings.enterLastName} id="lastName2"/>
                            </div>
                            <div className="form-group w-100">
                                <label htmlFor="firstName2">{strings.firstName}</label>
                                <input type="text" className="form-control" placeholder={strings.enterFirstName} id="firstName2"/>
                            </div>

                            {subscriptionType.includes('printedSubs') &&
                                <div className="form-group w-100">
                                    <label htmlFor="address2">{strings.address}</label>
                                    <input type="text" className="form-control" placeholder={strings.enterAddress} id="address2"/>
                                </div>
                            }
                            {subscriptionType.includes('electronSubs') &&
                                <div className="form-group w-100">
                                    <label htmlFor="emailAddress2">{strings.email}</label>
                                    <input type="text" className="form-control" placeholder={strings.enterEmail} id="emailAddress2"/>
                                </div>
                            }
                            <div className="form-group w-100">
                                <label htmlFor="phoneNumber2">{strings.phoneNumber}</label>
                                <input type="number" className="form-control" placeholder={strings.enterPhoneNumber} id="phoneNumber2" onChange={setPhoneNumber}/>
                            </div>

                        </div>

                        <div className="modal-footer justify-content-center d-flex">
                            <button type="button" className="btn btn-success"
                                    onClick={saveElectronSubscription}>{strings.save}</button>

                            {(subscriberId !== undefined && subscriberId !== null && subscriberId.length > 0) &&
                            <form id="clickForm" action="https://my.click.uz/services/pay" method="get"
                                  target="_blank">
                                <input type="hidden" name="service_id" value="17823"/>
                                <input type="hidden" name="merchant_id" value=""/>
                                <input type="hidden" name="merchant_user_id" value=""/>
                                <input type="hidden" name="amount" value={25000}/>
                                <input type="hidden" name="transaction_param" value={phoneNumber}/>
                                <input type="hidden" name="return_url"
                                       value="http://dhrjournal.uz/api/subscription/payment/click"/>

                                <input type={"submit"} className="btn btn-info" value={strings.payment}/>
                            </form>
                            }

                            <button type="button" className="btn btn-danger"
                                    data-dismiss="modal">{strings.cancel}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SubscriptionView);